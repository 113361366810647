import { api } from '@/api'

export default {
  // 后端获取路由数据
  routeList: () => api.get('app/route/list', {
    baseURL: '/mock/',
  }),

  // 基于文件系统路由模式下，后端获取导航菜单数据
  menuList: () => api.get('app/menu/list', {
    baseURL: '/mock/',
  }),
  // 来酷系统导航
  getLingoPageUrlsReq: (params: {
    sign: string
    pid: string
    search: string
    user_stamp: string
  }) => api.get('getUrls', { params }),
}
