interface itfRes<T = {}[] | {} | []> {
  data: {
    code: number
    msg: string | undefined
    data: T
    // reason?: string
    // page?: resPage
  }
}
declare module 'axios' {
  interface AxiosResponse extends itfRes {}
}

import axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios'

// import qs from 'qs'
import { isObject } from 'lodash-es'
import { ElMessageBox, ElMessage } from 'element-plus'
import useUserStore from '@/store/modules/user'
import router from '@/router'
import {
  // 获取地址栏参数功能
  getUrlParam,
} from '@/utils'
import {
  getSSToken,
  redirectToLogin,
  setSSToken,
  setLecoomisSeesionExpired,
  getLecoomisSeesionExpired
} from '@/utils/auth'

// 调用userStore退出接口并跳转到登录
function outTo() {
  const userStore = useUserStore()
  let expired = getLecoomisSeesionExpired()
  if (expired === undefined || Number(expired) <= 0) {
    setLecoomisSeesionExpired(1)
    expired = getLecoomisSeesionExpired()
  } else {
    // expired = Number(expired)
    // expired += 1
    // setLecoomisSeesionExpired(expired)
    // expired = getLecoomisSeesionExpired()
    setLecoomisSeesionExpired(0)
  }
  if (Number(expired) === 1) {
    userStore.lecooLogout().then(res => {
      // console.log('res', res)
      /* ElMessage({
        message: res.msg,
        type: 'success'
      }) */
      // console.log('redirect to login')
      redirectToLogin()
    })
  }
}
// common
const commonApi: AxiosInstance = axios.create({
  baseURL: (import.meta.env.DEV && import.meta.env.VITE_OPEN_PROXY === 'true') ? '/' : import.meta.env.VITE_APP_MIS_API_BASEURL,
  timeout: 1000 * 60 * 5,
  responseType: 'json',
})
// custom header
const customCommonHeaders = {
  // 'Content-Type': 'application/json',
  'Authorization': '',
}
customCommonHeaders.Authorization = getUrlParam('auth')?.includes('+') ? getUrlParam('auth')?.replace('+',
' ') : getUrlParam('auth')

// console.log('===> axios interceptors req before')
commonApi.interceptors.request.use(
  (request: AxiosRequestConfig) => {
    const userStore = useUserStore()
    /**
     * 全局拦截请求发送前提交的参数
     * 以下代码为示例，在请求头里带上 token 信息
     */
    if (userStore.isLogin && request.headers) {
      request.headers.Token = userStore.token
    }
    if (request.headers) {
      // console.log('asdf')
      request.headers['Content-Type'] = customCommonHeaders['Content-Type']
      request.headers.Authorization = customCommonHeaders.Authorization
    }
    // 是否将 POST 请求参数进行字符串化处理
    if (request.method === 'post') {
      // request.data = qs.stringify(request.data, {
      //   arrayFormat: 'brackets',
      // })
    }
    return request
  },
)

commonApi.interceptors.response.use(
  (response: AxiosResponse) => {
    // const userStore = useUserStore()
    /**
     * 全局拦截请求发送后返回的数据，如果数据有报错则在这做全局的错误提示
     * 假设返回数据格式为：{ status: 1, error: '', data: '' }
     * 规则是当 status 为 1 时表示请求成功，为 0 时表示接口需要登录或者登录状态失效，需要重新登录
     * 请求出错时 error 会返回错误信息
     */
    // console.log('axios response', response)
    // ElMessage({
    //   message: response.data.code,
    //   type: 'success',
    // })
    if (!Object.prototype.hasOwnProperty.call(response.data, 'data') && !isObject(response.data)) {
      if (response.data.indexOf('无权访问') >= 0) {
        ElMessage({
          message: '无权访问',
          type: 'error',
          duration: 5 * 1000
        })
        return Promise.reject(response.data)
      }
    } else if (response.data.code.toString() === '200') {
      return Promise.resolve(response.data)
    } else if (response.data.code.toString() === '201') {
      /* 商户列表在用，表示提交时已存在数据 */
      console.log('reject 201')
      return Promise.reject(response.data)
    } else if (response.data.code.toString() === '401') {
      return Promise.reject(response.data)
    } else if (response.data.code.toString().indexOf('3') === 0 || response.data.code.toString().indexOf('4') === 0) {
      /* code is 4xx type */
      // console.log('ajax 403', response)
      // return Promise.reject(response.data)
      return Promise.resolve(response.data)
    } else if (response.data.code.toString() === '600') {
      console.log('axios res 600')
      // let expired = getLecoomisSeesionExpired()
      // if (expired === undefined || Number(expired) <= 0) {
      //   setLecoomisSeesionExpired(1)
      //   expired = getLecoomisSeesionExpired()
      // } else {
      //   expired = Number(expired)
      //   expired += 1
      //   setLecoomisSeesionExpired(expired)
      //   expired = getLecoomisSeesionExpired()
      // }
      // if (Number(expired) === 1) {
      //   useUserStore().lecooLogout().then(() => {
      //     redirectToLogin()
      //   })
      // }
      outTo()
    } else if (response.data.code.toString() !== '200') {
      /* ${response.data.code}  */
      ElMessage({
        message: `${response.data.msg}`,
        type: 'error',
      })
      // showNotify({ type: 'danger', message: `${response.data.code} ${response.data.msg}` });
    } else {
      return Promise.reject(response.data)
    }
    // let expired = getLecoomisSeesionExpired()
    // if (expired !== undefined || Number(expired) <= 0) {
    //   userStore.lecooLogout()
    //   router.push({path: '/'})
    // }

    // 退出逻辑判断
    // else {
    //   useUserStore().logout()
    // }
  },
  (error) => {
    let message = error.message
    if (message === 'Network Error') {
      message = '后端网络故障'
    } else if (message.includes('timeout')) {
      message = '接口请求超时'
    } else if (message.includes('Request failed with status code')) {
      message = `接口${message.substr(message.length - 3)}异常`
    } else if (error.response.status.toString() === '419') {
      let expired = getLecoomisSeesionExpired()
      if (expired === undefined || Number(expired) <= 0) {
        setLecoomisSeesionExpired(1)
        expired = getLecoomisSeesionExpired()
      } else {
        expired = Number(expired)
        expired += 1
        setLecoomisSeesionExpired(expired)
        expired = getLecoomisSeesionExpired()
      }
      if (Number(expired) === 1) {
        redirectToLogin()
      }
    }
    ElMessage({
      message,
      type: 'error',
    })
    // outTo()
    return Promise.reject(error)
  },
)

// 二进制文件类
const serviceBlobApi: AxiosInstance = axios.create({
  baseURL: (import.meta.env.DEV && import.meta.env.VITE_OPEN_PROXY === 'true') ? '/' : import.meta.env.VITE_APP_MIS_API_BASEURL,
  timeout: 1000 * 60 * 5,
  // responseType: 'json'
  // 20240611
  responseType: 'arraybuffer'
})
// custom header
// const customCommonHeaders = {
//   // 'Content-Type': 'application/json',
//   'Authorization': '',
// }
// customCommonHeaders.Authorization = getUrlParam('auth')?.includes('+') ? getUrlParam('auth')?.replace('+',
// ' ') : getUrlParam('auth')

// console.log('===> axios interceptors req before')
serviceBlobApi.interceptors.request.use(
  (request: AxiosRequestConfig) => {
    const userStore = useUserStore()
    /**
     * 全局拦截请求发送前提交的参数
     * 以下代码为示例，在请求头里带上 token 信息
     */
    if (userStore.isLogin && request.headers) {
      request.headers.Token = userStore.token
    }
    if (request.headers) {
      // console.log('asdf')
      request.headers['Content-Type'] = customCommonHeaders['Content-Type']
      request.headers.Authorization = customCommonHeaders.Authorization
    }
    // 是否将 POST 请求参数进行字符串化处理
    if (request.method === 'post') {
      // request.data = qs.stringify(request.data, {
      //   arrayFormat: 'brackets',
      // })
    }
    return request
  },
)

serviceBlobApi.interceptors.response.use((response: AxiosResponse) => {
  // const userStore = useUserStore()
  /**
   * 全局拦截请求发送后返回的数据，如果数据有报错则在这做全局的错误提示
   * 假设返回数据格式为：{ status: 1, error: '', data: '' }
   * 规则是当 status 为 1 时表示请求成功，为 0 时表示接口需要登录或者登录状态失效，需要重新登录
   * 请求出错时 error 会返回错误信息
   */
  // console.log('axios response', response)
  // ElMessage({
  //   message: response.data.code,
  //   type: 'success',
  // })
  if (response.status.toString() === '200') {
    return Promise.resolve(response.data)
  } else {
    switch (response.status.toString()) {
      case '500':
        /* 改为统一处理没有msg的情况 */
        if (!response.statusText) {
          response.statusText = "系统异常"
        }
        return Promise.reject(response)
      default:
        return Promise.reject(response)
    }
  }
    // 退出逻辑判断
    // else {
    //   useUserStore().logout()
    // }
  },
  (error) => {
    let message = error.message
    if (message === 'Network Error') {
      message = '后端网络故障'
    } else if (message.includes('timeout')) {
      message = '接口请求超时'
    } else if (message.includes('Request failed with status code')) {
      message = `接口${message.substr(message.length - 3)}异常`
    } else if (error.response.status.toString() === '419') {
      let expired = getLecoomisSeesionExpired()
      if (expired === undefined || Number(expired) <= 0) {
        setLecoomisSeesionExpired(1)
        expired = getLecoomisSeesionExpired()
      } else {
        expired = Number(expired)
        expired += 1
        setLecoomisSeesionExpired(expired)
        expired = getLecoomisSeesionExpired()
      }
      if (Number(expired) === 1) {
        redirectToLogin()
      }
    }
    ElMessage({
      message,
      type: 'error',
    })
    // outTo()
    return Promise.reject(error)
  },
)

const api = axios.create({
  baseURL: (import.meta.env.DEV && import.meta.env.VITE_OPEN_PROXY === 'true') ? '/' : import.meta.env.VITE_APP_MIS_API_BASEURL,
  timeout: 1000 * 60,
  responseType: 'json',
})

const customHeaders = {
  'Content-Type': 'application/json',
  'Authorization': '',
}
// let tmpCustomHeaders = getSSToken()
customHeaders.Authorization = getSSToken() || ''
api.interceptors.request.use(
  (request) => {
    const userStore = useUserStore()
    /**
     * 全局拦截请求发送前提交的参数
     * 以下代码为示例，在请求头里带上 token 信息
     */
    if (userStore.isLogin && request.headers) {
      request.headers.Token = userStore.token
    }
    if (request.headers) {
      request.headers['Content-Type'] = customHeaders['Content-Type']
      request.headers.Authorization = customHeaders.Authorization
    }
    // 是否将 POST 请求参数进行字符串化处理
    if (request.method === 'post') {
      // request.data = qs.stringify(request.data, {
      //   arrayFormat: 'brackets',
      // })
    }
    return request
  },
)

api.interceptors.response.use(
  (response: AxiosResponse<any, any>) => {
    /**
     * 全局拦截请求发送后返回的数据，如果数据有报错则在这做全局的错误提示
     * 假设返回数据格式为：{ status: 1, error: '', data: '' }
     * 规则是当 status 为 1 时表示请求成功，为 0 时表示接口需要登录或者登录状态失效，需要重新登录
     * 请求出错时 error 会返回错误信息
     */
    // if (response.data.status === 1) {
    //   if (response.data.error !== '') {
    //     // 这里做错误提示，如果使用了 element plus 则可以使用 Message 进行提示
    //     // ElMessage.error(options)
    //     return Promise.reject(response.data)
    //   }
    // }
    // else {
    //   useUserStore().logout()
    // }
    console.log('at request')
    if (response.data.code !== 200) {
      if (response.data.code.toString().indexOf('40') === 0) {
        /* ElMessage({
          message: `${response.data.code} ${response.data.msg}`,
          type: 'error',
        }) */
        setLecoomisSeesionExpired(1)
        console.log('login')
        useUserStore().lecooLogout()
      }
      else {
        /* ${response.data.code}  */
        ElMessage({
          message: `${response.data.msg}`,
          type: 'error',
        })
        // showNotify({ type: 'danger', message: `${response.data.code} ${response.data.msg}` });
        if (response.data.error !== '') {
          // 这里做错误提示，如果使用了 element plus 则可以使用 Message 进行提示
          // ElMessage.error(options)
          return Promise.reject(response.data)
        }
      }
    }
    let expired = getLecoomisSeesionExpired()
    if (expired !== undefined || Number(expired) <= 0) {
      useUserStore().lecooLogout()
      router.push({path: '/'})
    }
    // 退出逻辑判断
    // else {
    //   useUserStore().logout()
    // }
    return Promise.resolve(response.data)
  },
  (error) => {
    let message = error.message
    if (message === 'Network Error') {
      message = '后端网络故障'
    }
    else if (message.includes('timeout')) {
      message = '接口请求超时'
    }
    else if (message.includes('Request failed with status code')) {
      message = `接口${message.substr(message.length - 3)}异常`
    }
    ElMessage({
      message,
      type: 'error',
    })
    return Promise.reject(error)
  },
)

const oldMisService = axios.create({
  // baseURL: (import.meta.env.DEV && import.meta.env.VITE_OPEN_PROXY === 'true') ? '/proxy/' : import.meta.env.VITE_APP_API_BASEURL,
  baseURL: (import.meta.env.DEV && import.meta.env.VITE_OPEN_PROXY === 'true') ? '/' : import.meta.env.VITE_APP_MIS_API_BASEURL,
  timeout: 1000 * 60,
  responseType: 'json',
})

const customOldHeaders = {
  'Content-Type': 'application/json',
  'Authorization': '',
}
// let tmpCustomHeaders = getSSToken()
customOldHeaders.Authorization = getSSToken() || ''
oldMisService.interceptors.request.use(
  (request) => {
    const userStore = useUserStore()
    /**
     * 全局拦截请求发送前提交的参数
     * 以下代码为示例，在请求头里带上 token 信息
     */
    if (userStore.isLogin && request.headers) {
      request.headers.Token = userStore.token
    }
    if (request.headers) {
      request.headers['Content-Type'] = customOldHeaders['Content-Type']
      request.headers.Authorization = customOldHeaders.Authorization
    }
    // 是否将 POST 请求参数进行字符串化处理
    if (request.method === 'post') {
      // request.data = qs.stringify(request.data, {
      //   arrayFormat: 'brackets',
      // })
    }
    return request
  },
)

oldMisService.interceptors.response.use(
  (response: AxiosResponse<any, any>) => {
    /**
     * 全局拦截请求发送后返回的数据，如果数据有报错则在这做全局的错误提示
     * 假设返回数据格式为：{ status: 1, error: '', data: '' }
     * 规则是当 status 为 1 时表示请求成功，为 0 时表示接口需要登录或者登录状态失效，需要重新登录
     * 请求出错时 error 会返回错误信息
     */
    // if (response.data.status === 1) {
    //   if (response.data.error !== '') {
    //     // 这里做错误提示，如果使用了 element plus 则可以使用 Message 进行提示
    //     // ElMessage.error(options)
    //     return Promise.reject(response.data)
    //   }
    // }
    // else {
    //   useUserStore().logout()
    // }
    // 等接口正确了改回上一种
    const res = {
      code: '200',
      data: response.data,
      message: 'ok'
    }
    console.log('at request')
    // if (response.data.code !== 200) {
    //   if (response.data.code.toString().indexOf('40') === 0) {
    //     /* ElMessage({
    //       message: `${response.data.code} ${response.data.msg}`,
    //       type: 'error',
    //     }) */
    //     setLecoomisSeesionExpired(1)
    //     console.log('login')
    //     useUserStore().lecooLogout()
    //   }
    //   else {
    //     /* ${response.data.code}  */
    //     ElMessage({
    //       message: `${response.data.msg}`,
    //       type: 'error',
    //     })
    //     // showNotify({ type: 'danger', message: `${response.data.code} ${response.data.msg}` });
    //     if (response.data.error !== '') {
    //       // 这里做错误提示，如果使用了 element plus 则可以使用 Message 进行提示
    //       // ElMessage.error(options)
    //       return Promise.reject(response.data)
    //     }
    //   }
    // }
    // let expired = getLecoomisSeesionExpired()
    // if (expired !== undefined || Number(expired) <= 0) {
    //   useUserStore().lecooLogout()
    //   router.push({path: '/'})
    // }
    if (res.code !== '200') {
      ElMessage({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        // to re-login
        ElMessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
          confirmButtonText: 'Re-Login',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          store.dispatch('user/resetToken').then(() => {
            location.reload()
          })
        })
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return Promise.resolve(response.data)
    }

    // 退出逻辑判断
    // else {
    //   useUserStore().logout()
    // }
  },
  (error) => {
    let message = error.message
    if (message === 'Network Error') {
      message = '后端网络故障'
    }
    else if (message.includes('timeout')) {
      message = '接口请求超时'
    }
    else if (message.includes('Request failed with status code')) {
      message = `接口${message.substr(message.length - 3)}异常`
    }
    ElMessage({
      message,
      type: 'error',
    })
    return Promise.reject(error)
  },
)

// export default api
export {
  api,
  serviceBlobApi,
  commonApi,
  oldMisService
}
