// 该文件为系统默认配置，请勿修改！！！

import type { RecursiveRequired, Settings } from '@/types/global'

const globalSettingsDefault: RecursiveRequired<Settings.all> = {
  app: {
    colorScheme: 'light',
    // elementSize: 'default',
    iconifyOfflineUse: false,
    // 如果权限功能开启，则需要对路由数据进行筛选过滤
    enablePermission: false,
    enableProgress: true,
    enableDynamicTitle: false,
    // 路由生成方式：frontend 前端, backend 后端服务, filesystem 基于文件系统（框架默认不启用该特性，如果需要开启，需要到应用配置里修改设置）。
    routeBaseOn: 'frontend',
    enableAppSetting: false,
  },
  home: {
    enable: true,
    title: '主页',
  },
  layout: {
    enableMobileAdaptation: false,
  },
  menu: {
    baseOn: 'frontend',
    /* side-两级菜单（带一个侧边栏，常见的菜单为侧边栏的次级）, single-单纯二级菜单（不显示一级的侧边栏）20231204 */
    menuMode: 'side',
    switchMainMenuAndPageJump: false,
    subMenuUniqueOpened: false,
    subMenuCollapse: false,
    enableSubMenuCollapseButton: true,
    enableHotkeys: false,
  },
  topbar: {
    mode: 'static',
  },
  toolbar: {
    enableFullscreen: false,
    enablePageReload: false,
    enableColorScheme: false,
  },
  breadcrumb: {
    enable: true,
  },
  navSearch: {
    enable: false,
    enableHotkeys: true,
  },
  copyright: {
    enable: false,
    dates: '',
    company: '',
    website: '',
    beian: '',
  },
}

export default globalSettingsDefault
