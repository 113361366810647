//
// waterMark 添加、清除水印方法（可在路由守卫处使用，也可在页面模块内使用） add 20220920/ updated 20220921
//

const watermark = {}
// 添加水印定时器
// let waterInterval = null
// window.waterInterval = null

function setWatermark(str: string) {
  // console.log('str', str)
  const id = '1.23452384164.123412415'

  if (document.getElementById(id) !== null) {
    document.body.removeChild(document.getElementById(id))
  }

  const can = document.createElement('canvas')
  can.width = 250
  can.height = 120

  const cans = can.getContext('2d')
  cans.rotate(-15 * Math.PI / 150)
  // PingFang-SC-light
  cans.font = '400 14px PingFang-SC'
  cans.fillStyle = 'rgba(0, 0, 0, 0.04)'
  cans.textAlign = 'left'
  cans.textBaseline = 'Middle'
  cans.fillText(str, can.width / 8, can.height / 2)

  const div = document.createElement('div')
  div.id = id
  div.style.pointerEvents = 'none'
  div.style.top = '56px'
  div.style.left = '200px'
  div.style.position = 'fixed'
  div.style.zIndex = '100000'
  div.style.width = `${document.documentElement.clientWidth - 200}px`
  div.style.height = `${document.documentElement.clientHeight - 56}px`
  div.style.background = `url(${can.toDataURL('image/png')}) left top repeat`
  document.body.appendChild(div)
  // let appMain = document.getElementById('appMain')
  // appMain.appendChild(div)
  return id
}

// 该方法只允许调用一次
// watermark.set = (str) => {
export function setWaterMark(str: string) {
  // const id = setWatermark(str)
  setWatermark(str)
  // waterInterval = setInterval(() => {
  // window.waterInterval = setInterval(() => {
  //   if (document.getElementById(id) === null) {
  //     id = setWatermark(str)
  //   }
  // }, 1000)
  // window.onresize = () => {
  //   setWatermark(str)
  // }
}

function outWatermark(id: string) {
  if (document.getElementById(id) !== null) {
    const div = document.getElementById(id)
    div.style.display = 'none'
  }
}
watermark.out = () => {
  const str = '1.23452384164.123412415'
  outWatermark(str)
}
// 移除水印方法
// watermark.remove = () => {
export function removeWaterMark() {
  console.log('remove water mark')
  const id = '1.23452384164.123412415'
  if (document.getElementById(id) !== null) {
    /* 去除水印先清定时器 */
    // clearTimeout(waterInterval)
    // clearTimeout(window.waterInterval)
    document.body.removeChild(document.getElementById(id))
  }
}
