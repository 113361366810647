/**
 * @descript 前端加、解密
 * @author zz81cn created on 20221204.
 * */

import CryptoJS from 'crypto-js'

// import { getSessionStorageToken, getAppToken, getTrustedSessionKey, getCryptoSwitch, getCryptoKey } from '@/utils/auth'

const CRYPTO_KEY = 'secret 123'
  /** @description AES加密（当前只用在登录密码加密） 20241012 */
export function aesEncryptParamByKeyJs(data, key) {
  // console.log('login aes', data, 'key', key)
  const pData = CryptoJS.enc.Utf8.parse(data)
  const pKey = CryptoJS.enc.Utf8.parse(key)
  // console.log('login aes', pData, 'key', pKey)
  const encJSON = CryptoJS.AES.encrypt(pData, pKey, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  }).toString()
  // console.log('pd', encJSON)
  return encJSON
}
export const aesUtil = {
  // 单独加密值（给后端传参）
  encryptPlainText(plaintext, key) {
    // console.log('encrypt plain text', plaintext, key)
    // const priKey = 'secret 123'
    let tmpResult = ''
    // let encrypted = CryptoJS.AES.encrypt(plaintext, CryptoJS.enc.Hex.parse(key), {
    //   mode: CryptoJS.mode.ECB,
    //   padding: CryptoJS.pad.Pkcs7
    // })
    // console.log('newSingleEncrypt encrypted', encrypted.ciphertext.toString())
    // return encrypted.ciphertext.toString()
    if (plaintext) {
      tmpResult = CryptoJS.AES.encrypt(plaintext.toString(), key = CRYPTO_KEY).toString()
      return tmpResult
    }
    else {
      return ''
    }
  },
  // 前端aes解密 'secret key 123'
  deCryptPlainText(plaintext, key) {
    // console.log('deCryptPlainText plain text', plaintext, key)
    // const priKey = 'secret 123'
    if (plaintext) {
      const bytes = CryptoJS.AES.decrypt(plaintext, key = CRYPTO_KEY)
      const encryptPwd = bytes.toString(CryptoJS.enc.Utf8)
      /* console.log('deCryptPlainText', encryptPwd) */
      return encryptPwd
    }
    else {
      return ''
    }
  },
  // 加密请求参数 Hex parse (Content-Type: application json)
  jsonEncrypt: function (plaintext, key = "") {
    /* console.log('jsonEncrypt plaintext', plaintext); */
    if (plaintext instanceof Object) { plaintext = JSON.stringify(plaintext) }
    let encrypted = CryptoJS.AES.encrypt(plaintext, CryptoJS.enc.Hex.parse(key), {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    })
    /* console.log('encrypted', encrypted.ciphertext.toString()) */
    return encrypted.ciphertext.toString()
  },
  // 解密请求参数 Hex parse (Content-Type: application/json)
  jsonDecrypt: function (plaintext, key) {
    let decrypt = CryptoJS.AES.decrypt(CryptoJS.format.Hex.parse(plaintext), CryptoJS.enc.Hex.parse(key), {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    })
    let decString = CryptoJS.enc.Utf8.stringify(decrypt)
    /* console.log('dep', decString) */
    return decString
  },
  /**
   * 加密请求参数FormData类型专用 form-urlencoded形式只对value部分加密，后续在axios实例根据Content-Type转换为application/x-www-form-urlencoded;charset=utf-8
   * 地址栏参数加密：加密开关打开时（url的token加密时），发送参数key为token的，要单独判断
   * */
  mapValEncrypt: function (plaintext, key = "") {
    console.log('Start mapValEncrypt start plaintext', plaintext, 'key ', key);
    let plainTextClone = _.cloneDeep(plaintext);
    Object.keys(plainTextClone).forEach((item, index) => {
      // // 现在模拟地址栏、header加密
      // if (item === 'token') {
      //   plainTextClone[item] = plainTextClone[item].toString();
      // } else if (item !== 'token') {
        let tempItem = CryptoJS.AES.encrypt(plainTextClone[item].toString(), CryptoJS.enc.Hex.parse(key), {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        })
        plainTextClone[item] = tempItem.ciphertext.toString()
      // }
    })
    /* console.log('mapValEncrypt encrypted ', plainTextClone); */
    return plainTextClone
  },
  // formData 解密
  mapValDecrypt: function (plaintext, key = "") {
    let deKey = key ? key : getCryptoKey();
    debugger
    let plainTextClone = _.cloneDeep(plaintext);
    Object.keys(plainTextClone).forEach((itemKey, index) => {
      // let tempItem = CryptoJS.AES.decrypt(CryptoJS.format.Hex.parse(plainTextClone[itemKey]), CryptoJS.enc.Hex.parse(key ? key : deKey), {
      let decData = CryptoJS.enc.Base64.parse(plainTextClone[itemKey]).toString(CryptoJS.enc.Utf8);
      let tempItem = CryptoJS.AES.decrypt(decData, CryptoJS.enc.Hex.parse(key ? key : deKey), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      })
      // console.log('plainTextClone[itemKey]', CryptoJS.enc.Utf8.stringify(tempItem))
      plainTextClone[itemKey] = CryptoJS.enc.Utf8.stringify(tempItem)
      // plainTextClone[itemKey] = tempItem
    })
    console.log('encrypted', plainTextClone)
    return plainTextClone
  },
  // 单独加密值（给后端传参）
  singleValEncrypt: function (plaintext, key) {
    let encrypted = CryptoJS.AES.encrypt(plaintext, CryptoJS.enc.Hex.parse(key), {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    })
    console.log('newSingleEncrypt encrypted', encrypted.ciphertext.toString())
    return encrypted.ciphertext.toString()
  },
  // 加密Key: value类型专用（前端专用：路由参数解密等）
  mapValBase64Encrypt: function (plaintext, key = "") {
    console.log('Start mapValEncrypt start plaintext', plaintext, 'key ', key);
    let plainTextClone = _.cloneDeep(plaintext);
    Object.keys(plainTextClone).forEach((item, index) => {
      let encJSON = CryptoJS.AES.encrypt(plainTextClone[item], CryptoJS.enc.Hex.parse(key), {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        }).toString();
      let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJSON))
      plainTextClone[item] = encData
    })
    /* console.log('mapValBase64Encrypt encrypted ', plainTextClone); */
    return plainTextClone
  },
  // 解密Key: value类型专用（前端专用：路由参数解密等）
  mapValBase64Decrypt: function (plaintext, key = "") {
    let deKey = key ? key : getCryptoKey();
    let plainTextClone = _.cloneDeep(plaintext);
    Object.keys(plainTextClone).forEach((itemKey, index) => {
      let decData = CryptoJS.enc.Base64.parse(plainTextClone[itemKey]).toString(CryptoJS.enc.Utf8);
      let bytes = CryptoJS.AES.decrypt(decData, CryptoJS.enc.Hex.parse(key ? key : deKey), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8)
      plainTextClone[itemKey] = bytes
    })
    console.log('mapValBase64Decrypt decrypted', plainTextClone)
    return plainTextClone
  },
  // 单独的val加密（前端专用：路由参数解密等）
  singleValBase64Encrypt: function (plaintext, key) {
    let encJSON = CryptoJS.AES.encrypt(plaintext, CryptoJS.enc.Hex.parse(key), {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString();
    console.log('singleValBase64Encrypt encrypted', CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJSON)))
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJSON))
  },
  // 单独的val解密（前端专用：路由参数解密等）
  singleValBase64Decrypt: function (plaintext, key) {
    let deKey = key ? key : getCryptoKey();
    let decData = CryptoJS.enc.Base64.parse(plaintext).toString(CryptoJS.enc.Utf8);
    let bytes = CryptoJS.AES.decrypt(decData, CryptoJS.enc.Hex.parse(key ? key : deKey), {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8)
    console.log('singleValBase64Decrypt decrypted', bytes)
    return bytes
  },
  genKey: function (expect = 16) {
    const random = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let str = ''
    while (str.length < expect) { str += random.charAt(Math.random() * random.length) }
    return str
  },
  // 针对data数据加密，配合rsa加密key的方案（暂时不用）
  /* encrypt: function (plaintext, key) {
    debugger
    if (plaintext instanceof Object) { plaintext = JSON.stringify(plaintext) }
    let encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(plaintext), CryptoJS.enc.Utf8.parse(key), {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    })
    return encrypted.toString()
  }, */
  // 针对data数据解密，配合rsa加密key的方案（暂时不用）
  /* decrypt: function (ciphertext, key) {
    let decrypt = CryptoJS.AES.decrypt(ciphertext, CryptoJS.enc.Utf8.parse(key), {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    })
    let decString = CryptoJS.enc.Utf8.stringify(decrypt).toString()
    if (decString.charAt(0) === '{' || decString.charAt(0) === '[') {
      decString = JSON.parse(decString)
    }
    return decString
  } */
}
