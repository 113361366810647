import { api, commonApi } from '@/api'

// enums
import { DEFAULT_PRODUCT_CODE } from '@/enums/roleEnums'

// 加解密工具
import { aesUtil } from '@/utils/encryptn'

// 判断角色
import { getBusinessType } from '@/utils/auth'

export default {
  // 登录
  login: (data: {
    account: string
    password: string
  }) => commonApi.post('user/login', data, {
    baseURL: '/mock/',
  }),

  // 获取权限
  permission: () => commonApi.get('user/permission', {
    baseURL: '/mock/',
  }),

  // 修改密码
  passwordEdit: (data: {
    password: string
    newpassword: string
  }) => commonApi.post('user/password/edit', data, {
    baseURL: '/mock/',
  }),
}
// 登录密码加密获取key 20241011
export function getPasswordKeyReq() {
  return commonApi({
    url: '/admin/getPasswordKey',
    method: 'get',
  })
}
// 获取全局配置接口
export function getGlobalConfig() {
  return commonApi({
    url: '/admin/frontGlobalConfig/globalConfig',
    method: 'get',
  })
}
// 获取导航菜单
/* export function getMenuList(data: any) {
  return commonApi({
    url: `${aesUtil.deCryptPlainText(getBusinessType()) === DEFAULT_PRODUCT_CODE ? '/admin' : '/business'}/frontGlobalConfig/getMenuList`,
    method: 'get',
    params: { data },
  })
} */
export function getMenuList() {
  return commonApi({
    url: `${aesUtil.deCryptPlainText(getBusinessType()) === DEFAULT_PRODUCT_CODE ? '/admin' : '/business'}/frontGlobalConfig/getMenuList`,
    method: 'get',
  })
}
// 退出
export function misLogout(data: {
  token: string
}) {
  return commonApi({
    url: `${aesUtil.deCryptPlainText(getBusinessType()) === DEFAULT_PRODUCT_CODE ? '/admin' : '/business'}/logout`,
    method: 'post',
    data,
  })
}
// 获取用户列表
export function getUserListReq(params: {
  id: string
  product_id: string
  username: string
  enabled: string
  page_size: number
  page: number
}) {
  return commonApi.get((aesUtil.deCryptPlainText(getBusinessType()) === DEFAULT_PRODUCT_CODE ? '/admin' : '/business') + '/user/list', {params})
}
// 获取用户详情
export function getUserDetailReq(params: {
  id: string
  product_id: string
}) {
  return commonApi.get((aesUtil.deCryptPlainText(getBusinessType()) === DEFAULT_PRODUCT_CODE ? '/admin' : '/business') + '/user/detail', {params})
}
// 上传用户头像 : {file: BinaryType}
/* export function postUserUploadAvatarReq(params: any) {
  return commonApi({
    method: 'post',
    url: (aesUtil.deCryptPlainText(getBusinessType()) === DEFAULT_PRODUCT_CODE ? '/admin' : '/business') + '/user/upload', params,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: params
  })
} */
export function postUserUploadAvatarReq(params: any) {
  return commonApi.post((aesUtil.deCryptPlainText(getBusinessType()) === DEFAULT_PRODUCT_CODE ? '/admin' : '/business') + '/user/upload', params)
}
// 创建用户
export function postUserAddReq(data) {
  return commonApi.post((aesUtil.deCryptPlainText(getBusinessType()) === DEFAULT_PRODUCT_CODE ? '/admin' : '/business') + '/user/add', data)
}
// 修改用户
export function postUserUpdateReq(params) {
  return commonApi.post((aesUtil.deCryptPlainText(getBusinessType()) === DEFAULT_PRODUCT_CODE ? '/admin' : '/business') + '/user/update', params)
}
// 禁用（参数：id，login_status: 账号是否禁用 0 正常 1为禁用，product_id:平台标识1老MIS 2为新版MIS 3为商户）
export function postUserDeleteReq(data) {
  return commonApi.post((aesUtil.deCryptPlainText(getBusinessType()) === DEFAULT_PRODUCT_CODE ? '/admin' : '/business') + '/user/delete', data)
}
// 获取商户列表
export function getBusinessListReq(data) {
  return commonApi.get('/admin/business/list', {data})
}
// 获取用户详情
export function getAdminBusinessDetailReq(data) {
  return commonApi.get('/admin/business/detail', {data})
}
// 创建用户
export function postAdminBusinessAddReq(data) {
  return commonApi.post('/admin/business/add', data)
}
// 修改用户
export function postAdminBusinessUpdateReq(data) {
  return commonApi.post('/admin/business/update', data)
}
// 禁用（参数：id，login_status: 账号是否禁用 0 正常 1为禁用，product_id:平台标识1老MIS 2为新版MIS 3为商户）
export function postAdminBusinessDeleteReq(data) {
  return commonApi.post('/admin/business/delete', data)
}
// 获取公司列表
export function getCompanyListReq(data) {
  return commonApi.get((aesUtil.deCryptPlainText(getBusinessType()) === DEFAULT_PRODUCT_CODE ? '/admin' : '/business') + '/company/companyList', {data})
}
// 获取挂账人列表
export function getMemberListApiReq(data) {
  return commonApi.get((aesUtil.deCryptPlainText(getBusinessType()) === DEFAULT_PRODUCT_CODE ? '/admin' : '/business') + '/members/memberListApi/get', {data})
}

// MIS平台用户接口结束

// 商户端接口开始
// 商户登录 20221121
export function businessLoginReq(data: {
  pop_owner_code: string
  username: string
  password: string
  captcha: string
  captchakey: string
}) {
  return commonApi.post('/business/businessLogin', data)
}
// 获取MVP全局配置接口
export function getMvpGlobalConfig() {
  return commonApi({
    url: '/business/frontGlobalConfig/mvpGlobalConfig',
    method: 'get',
  })
}
// business 首次登录修改密码(https://docs.lecoosys.com/web/#/40/579) old_password string 初始化的旧密码, password string 新密码, confirm_password string 确认新密码 20221130
export function businessFirstChangePwdReq(data: {
  old_password: string
  password: string
  confirm_password: string
}) {
  return commonApi.post('/business/firstChangePwd', data)
}
// 获取公司列表
export function getCompanyListSearchReq(data: {
  q: string
}) {
  return commonApi({
    url: `${aesUtil.deCryptPlainText(getBusinessType()) === DEFAULT_PRODUCT_CODE ? '/admin' : '/business'}/company/selectListSearch`,
    method: 'get',
    params: data,
  })
}
// 获取部门列表
export function getDepartmentListSearchReq(data: {
  q: string
}) {
  return commonApi({
    url: `${aesUtil.deCryptPlainText(getBusinessType()) === DEFAULT_PRODUCT_CODE ? '/admin' : '/business'}/department/selectListSearch`,
    method: 'get',
    params: data,
  })
}
// 获取集团列表
export function getGroupListReq(data: {
  name: string
  contacts: string
  mobile: string
}) {
  return commonApi({
    url: `${aesUtil.deCryptPlainText(getBusinessType()) === DEFAULT_PRODUCT_CODE ? '/admin' : '/business'}/group/selectListSearch`,
    method: 'get',
    params: data,
  })
}

// 角色 role
// 获取角色列表
export function getRolesListReq(params) {
  return commonApi.get((aesUtil.deCryptPlainText(getBusinessType()) === DEFAULT_PRODUCT_CODE ? '/admin' : '/business') + '/roles/list',{params})
}
// 获取角色详情
export function getRolesDetailReq(params) {
  return commonApi.get((aesUtil.deCryptPlainText(getBusinessType()) === DEFAULT_PRODUCT_CODE ? '/admin' : '/business') + '/roles/detail', {params})
}
// 获取角色选择列表
export function getRoleGetListReq(params: {
  product_id: string
  type: string
}) {
  return commonApi.get((aesUtil.deCryptPlainText(getBusinessType()) === DEFAULT_PRODUCT_CODE ? '/admin' : '/business') + '/role/getList', {params})
}
// 创建角色
export function postRoleAddReq(params) {
  return commonApi.post((aesUtil.deCryptPlainText(getBusinessType()) === DEFAULT_PRODUCT_CODE ? '/admin' : '/business') + '/roles/add',
    params)
}
// 编辑角色
export function postRoleUpdateReq(params) {
  return commonApi.post((aesUtil.deCryptPlainText(getBusinessType()) === DEFAULT_PRODUCT_CODE ? '/admin' : '/business') + '/roles/update', params)
}
// 删除角色
export function postRoleDeleteReq(params) {
  return commonApi.post((aesUtil.deCryptPlainText(getBusinessType()) === DEFAULT_PRODUCT_CODE ? '/admin' : '/business') + '/roles/delete', params)
}

// 权限 permission
// 获取权限列表
export function getPermissionListReq(params: {
  product_id: string
  page_size: number
  page: number
}) {
  return commonApi.get((aesUtil.deCryptPlainText(getBusinessType()) === DEFAULT_PRODUCT_CODE ? '/admin' : '/business') + '/permission/list', { params })
}
// 获取权限选择列表
export function getPermissionGetListReq(params) {
  return commonApi.get((aesUtil.deCryptPlainText(getBusinessType()) === DEFAULT_PRODUCT_CODE ? '/admin' : '/business') + '/permission/getList', { params })
}
// 创建权限
export function postPermissionAddReq(params) {
  return commonApi.post((aesUtil.deCryptPlainText(getBusinessType()) === DEFAULT_PRODUCT_CODE ? '/admin' : '/business') + '/permission/add', params)
}
// 编辑权限
export function postPermissionUpdateReq(params) {
  return commonApi.post((aesUtil.deCryptPlainText(getBusinessType()) === DEFAULT_PRODUCT_CODE ? '/admin' : '/business') + '/permission/update', params)
}
// 删除权限
export function postPermissionDeleteReq(params: {
  id: string
  product_id: string
}) {
  return commonApi.post((aesUtil.deCryptPlainText(getBusinessType()) === DEFAULT_PRODUCT_CODE ? '/admin' : '/business') + '/permission/delete', params)
}
// 获取权限详情
export function getPermissionDetailReq(params) {
  return commonApi.get((aesUtil.deCryptPlainText(getBusinessType()) === DEFAULT_PRODUCT_CODE ? '/admin' : '/business') + '/permission/detail', { params })
}
// http方法获取
export function getHttpListReq() {
  return commonApi.get((aesUtil.deCryptPlainText(getBusinessType()) === DEFAULT_PRODUCT_CODE ? '/admin' : '/business') + '/http/list')
}
