import { cloneDeep, isString } from 'lodash-es'
import path from 'path-browserify'

// 年月日时分秒 2021-07-09 16:48:21
export function getYYYYMMDDHhMmSs(date) {
  /* console.log('get yyyy-mm-dd hh m s', date) */
  const FullYear = date.getFullYear()
  const Month = ((date.getMonth() + 1) < 10) ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  const Day = (date.getDate() < 10) ? '0' + date.getDate() : date.getDate()
  const Hour = (date.getHours() < 10) ? '0' + date.getHours() : date.getHours()
  const Minutes = (date.getMinutes() < 10) ? '0' + date.getMinutes() : date.getMinutes()
  const Seconds = (date.getSeconds() < 10) ? '0' + date.getSeconds() : date.getSeconds()
  return FullYear + '-' + Month + '-' + Day + ' ' + Hour + ':' + Minutes + ':' + Seconds
}
/** @description 千位符 20220707 */
export function thousandsPlaceSign(num: any) {
  // 先提取整数部分
  let result = num.toString().replace(/\d+/, function(n) {
    return n.replace(/(\d)(?=(\d{3})+$)/g,function($1) {
      return $1 + ','
    })
  })
  return result
}
// -Math.abs(
export function formatNumber(num: number) {
  // console.log('formatNumber', num)
  const numStr = num.toString()
  /* 标记是否负值 */
  let isNegN: Boolean = false
  // console.log('num isNegN', numStr.includes('-'))
  // 处理负数
  if (numStr.includes('-')) {
    isNegN = true
    num = Number(numStr.replace('-', ''))
  } else {
    num = Number(numStr)
  }
  /* if (num < 0) {
    return `${num}`
  }
  else if (num === 0 || (num > 0 && num < 10000)) {
    if (isNegN && (num > 0 && num < 10000)) {
      return `-${num}`
    } else {
      return `${num}`
    } */
  if (num === 0) {
    return `${num}`
  } else if (num >= 10000) {
    // return (num / 10000).toFixed(2) + '万'
    if (isNegN) {
      return -Math.abs(Number((num / 10000).toFixed(2)))
    }
    else {
      return (num / 10000).toFixed(2)
    }
  } else if (num < 10000) {
    // return (num / 10000).toFixed(2) + '万'
    if (isNegN) {
      // return -Math.abs((num / 10000).toFixed(2))
      return -(num / 10000).toFixed(2)
    }
    else {
      return (num / 10000).toFixed(2)
    }
  } else {
    return '0'
  }
}
/**
 * Created by PanJiaChen on 16/11/18.
 * Modify by zzl81cn on 22/12/21
 */

// 降维处理（菜单专用） 20230421
export function flattenDeep(arr = [], childs = 'Children'): any {
  // console.log('flattenDeep arr', arr)
  return arr.reduce((flat, item) => {
    /* delete item.title */
    const tmpMeta = JSON.parse(item.meta)
    item.meta = tmpMeta
    /* console.log('flattenDeep item', item) */
    return flat.concat(
      item,
      item[childs] ? flattenDeep(item[childs], childs) : []
    )
  }, [])
}

/**
 * @description 浮点数计算（解决js原生number精度问题）
 * @param arg1 num
 * @param arg2 num
 * @param fixed num 返回值的小数位长度
 * */
export function accMul(arg1, arg2, fixed) {
  let m = 0
  const s1 = arg1.toString()
  const s2 = arg2.toString()
  // 因为是乘法计算，需要将小数点后面的位数累计追加给m，方便利用Math.pow用x的y次幂的方式得出除数
  try {
    m += s1.split('.')[1].length
  } catch (e) {
    // console.log('e', e)
  }
  try {
    m += s2.split('.')[1].length
  } catch (e) {
    // console.log('e', e)
  }
  const result = Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m)
  return result.toFixed(fixed)
}
// sourceDate, monthNum = 3
export function getFutureMonthDay(monthNum = 3) {
  // const d = new Date(sourceDate);
  const d = new Date();
  //来源
  const SY = d.getFullYear();
  const SM = d.getMonth();
  const SD = d.getDate();
  //结果
  const mydate = new Date(SY, String(SM + monthNum).padStart(2, '0'), String(SD).padStart(2, '0'));
  const yyyy = mydate.getFullYear();
  let MM = (mydate.getMonth() + 1).toString().padStart(2, '0');
  let dd = mydate.getDate().toString().padStart(2, '0');
  //如果月份是2月，就进行闰年和非闰年处理，防止出现类似2月30的情况
  if (MM == 2 && dd > 28) {
    if (yyyy % 100 == 0 && yyyy % 400 == 0) {
      dd = 29
    } else if (yyyy % 100 != 0 && yyyy % 4 == 0) {
      dd = 29;
    } else {
      dd = 28;
    }
  }
  console.log(yyyy, '-', MM, '-', dd);
  return `${yyyy}-${MM}-${dd}`;
}
/**
 * @description 数字转中文数码
 *
 * @param {Number|String}   num     数字[正整数]
 * @param {String}          type    文本类型，lower|upper，默认upper
 *
 * @example number2text(100000000) => "壹亿元整"
 */
export const number2text = (num, type = 'upper') => {
  console.log('num 2 text', num, Math.sign(num))
  console.log('num', Math.sign(num) === -1)
  const isLow = Math.sign(num) === -1
  let number = 0
  if (isLow) {
    num = Math.abs(num)
  } number = num
  // 配置
  const confs = {
    lower: {
      num: ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'],
      unit: ['', '十', '百', '千', '万'],
      level: ['', '万', '亿']
    },
    upper: {
      num: ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'],
      unit: ['', '拾', '佰', '仟'],
      level: ['', '万', '亿']
    },
    decimal: {
      unit: ['分', '角']
    },
    maxNumber: 999999999999.99
  }

  // 过滤不合法参数
  if (Number(number) > confs.maxNumber) {
    console.error(`The maxNumber is ${confs.maxNumber}. ${number} is bigger than it!`)
    return false
  }

  const conf = confs[type]
  const numbers = String(Number(number).toFixed(2)).split('.')
  const integer = numbers[0].split('')
  const decimal = Number(numbers[1]) === 0 ? [] : numbers[1].split('')

  // 四位分级
  const levels = integer.reverse().reduce((pre, item, idx) => {
    const level = pre[0] && pre[0].length < 4 ? pre[0] : []
    const value = item === '0' ? conf.num[item] : conf.num[item] + conf.unit[idx % 4]
    level.unshift(value)

    if (level.length === 1) {
      pre.unshift(level)
    } else {
      pre[0] = level
    }

    return pre
  }, [])

  // 整数部分
  const _integer = levels.reduce((pre, item, idx) => {
    let _level = conf.level[levels.length - idx - 1]
    let _item = item.join('').replace(/(零)\1+/g, '$1') // 连续多个零字的部分设置为单个零字

    // 如果这一级只有一个零字，则去掉这级
    if (_item === '零') {
      _item = ''
      _level = ''

    // 否则如果末尾为零字，则去掉这个零字
    } else if (_item[_item.length - 1] === '零') {
      _item = _item.slice(0, _item.length - 1)
    }

    return pre + _item + _level
  }, '')

  // 小数部分
  const _decimal = decimal.map((item, idx) => {
    const unit = confs.decimal.unit
    const _unit = item !== '0' ? unit[unit.length - idx - 1] : ''

    return `${conf.num[item]}${_unit}`
  }).join('')

  if (isLow) {
    return `负${_integer}元` + (_decimal || '整')
  } else {
    // 如果是整数，则补个整字
    return `${_integer}元` + (_decimal || '整')
  }
}

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 */
export function handleTree(data, id, parentId, children) {
  const config = {
    id: id || 'id',
    parentId: parentId || 'parentId',
    childrenList: children || 'children'
  }

  var childrenListMap = {}
  var nodeIds = {}
  var tree = []

  for (const d of data) {
    const parentId = d[config.parentId]
    if (childrenListMap[parentId] == null) {
      childrenListMap[parentId] = []
    }
    nodeIds[d[config.id]] = d
    childrenListMap[parentId].push(d)
  }

  for (const d of data) {
    const parentId = d[config.parentId]
    if (nodeIds[parentId] == null) {
      tree.push(d)
    }
  }

  for (const t of tree) {
    adaptToChildrenList(t)
  }

  function adaptToChildrenList(o) {
    if (childrenListMap[o[config.id]] !== null) {
      o[config.childrenList] = childrenListMap[o[config.id]]
    }
    if (o[config.childrenList]) {
      for (const c of o[config.childrenList]) {
        adaptToChildrenList(c)
      }
    }
  }
  return tree
}

// 生成JS随机生成密码 (至少包含一个大写字母，小写字母，数字，特殊符号) 20221115 cre/20221221 up
export function randomPassword(length) {
  length = Number(length)
  // Limit length
  if (length < 6) {
    length = 6
  } else if (length > 16) {
    length = 16
  }
  // const passwordArray = ['ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'abcdefghijklmnopqrstuvwxyz', '1234567890', '!@#$%&*()']
  const passwordArray = ['ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'abcdefghijklmnopqrstuvwxyz', '1234567890', '!@#_']
  var password = []
  let n = 0
  for (let i = 0; i < length; i++) {
    // If password length less than 9, all value random
    if (password.length < (length - 4)) {
      // Get random passwordArray index
      const arrayRandom = Math.floor(Math.random() * 4)
      // Get password array value
      const passwordItem = passwordArray[arrayRandom]
      // Get password array value random index
      // Get random real value
      const item = passwordItem[Math.floor(Math.random() * passwordItem.length)]
      password.push(item)
    } else {
      // If password large then 9, lastest 4 password will push in according to the random password index
      // Get the array values sequentially
      const newItem = passwordArray[n]
      const lastItem = newItem[Math.floor(Math.random() * newItem.length)]
      // Get array splice index
      const spliceIndex = Math.floor(Math.random() * password.length)
      password.splice(spliceIndex, 0, lastItem)
      n++
    }
  }
  return password.join('')
}
export function resolveRoutePath(basePath: string, routePath?: string) {
  return basePath ? path.resolve(basePath, routePath ?? '') : routePath ?? ''
}
// 存储SessionStorage
export function setSessionStorage(name: string, content: string) {
  if (!name) {
    return
  }
  if (typeof content !== 'string') {
    content = JSON.stringify(content)
  }
  window.sessionStorage.setItem(name, content)
}

// 获取SessionStorage
export function getSessionStorage(name: string) {
  if (!name) {
    return ''
  }
  return window.sessionStorage.getItem(name)
}
/**
 * 删除SessionStorage
 */
export function removeSessionStorage(name: string) {
  if (!name) {
    return
  }
  window.sessionStorage.removeItem(name)
}
// JS`正则表达式`获取地址栏url参数：调用方法 var title = getUrlParam('title')
export function getUrlParam(name: string) {
  // 构造一个含有目标参数的正则表达式对象
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`)
  // 匹配目标参数
  const r = window.location.search.substring(1).match(reg)
  if (r != null) {
    return decodeURIComponent(r[2])
  }
  // 返回参数值
  return null
}

// 检测pad
export function detectIsPad() {
  const isPad = document.body.clientWidth > 960
  // console.log('detect is pad', isPad)
  return isPad
}
// generator
export function generatorFailtureTime() {
  return Math.ceil(new Date().getTime() / 1000) + 24 * 60 * 60
}

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time: any, cFormat: any) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string')) {
      if ((/^[0-9]+$/.test(time))) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

// 生成JS随机生成密码 (至少包含一个大写字母，小写字母，数字，特殊符号) 20221115 cre/20221221 up
export function generatorRandomPassword(length: number) {
  length = Number(length)
  // Limit length
  if (length < 6) {
    length = 6
  } else if (length > 16) {
    length = 16
  }
  // const passwordArray = ['ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'abcdefghijklmnopqrstuvwxyz', '1234567890', '!@#$%&*()']
  const passwordArray = ['ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'abcdefghijklmnopqrstuvwxyz', '1234567890', '!@#_']
  var password = []
  let n = 0
  for (let i = 0; i < length; i++) {
    // If password length less than 9, all value random
    if (password.length < (length - 4)) {
      // Get random passwordArray index
      const arrayRandom = Math.floor(Math.random() * 4)
      // Get password array value
      const passwordItem = passwordArray[arrayRandom]
      // Get password array value random index
      // Get random real value
      const item = passwordItem[Math.floor(Math.random() * passwordItem.length)]
      password.push(item)
    } else {
      // If password large then 9, lastest 4 password will push in according to the random password index
      // Get the array values sequentially
      const newItem = passwordArray[n]
      const lastItem = newItem[Math.floor(Math.random() * newItem.length)]
      // Get array splice index
      const spliceIndex = Math.floor(Math.random() * password.length)
      password.splice(spliceIndex, 0, lastItem)
      n++
    }
  }
  return password.join('')
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  let s = str.length
  for (var i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i)
    if (code > 0x7f && code <= 0x7ff) s++
    else if (code > 0x7ff && code <= 0xffff) s += 2
    if (code >= 0xDC00 && code <= 0xDFFF) i--
  }
  return s
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
  const newArray = []
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i])
    }
  }
  return newArray
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return ''
  return cleanArray(
    Object.keys(json).map(key => {
      if (json[key] === undefined) return ''
      return encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
    })
  ).join('&')
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ')
  if (!search) {
    return {}
  }
  const obj = {}
  const searchArr = search.split('&')
  searchArr.forEach(v => {
    const index = v.indexOf('=')
    if (index !== -1) {
      const name = v.substring(0, index)
      const val = v.substring(index + 1, v.length)
      obj[name] = val
    }
  })
  return obj
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
  const div = document.createElement('div')
  div.innerHTML = val
  return div.textContent || div.innerText
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
  if (typeof target !== 'object') {
    target = {}
  }
  if (Array.isArray(source)) {
    return source.slice()
  }
  Object.keys(source).forEach(property => {
    const sourceProperty = source[property]
    if (typeof sourceProperty === 'object') {
      target[property] = objectMerge(target[property], sourceProperty)
    } else {
      target[property] = sourceProperty
    }
  })
  return target
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
  if (!element || !className) {
    return
  }
  let classString = element.className
  const nameIndex = classString.indexOf(className)
  if (nameIndex === -1) {
    classString += '' + className
  } else {
    classString =
      classString.substr(0, nameIndex) +
      classString.substr(nameIndex + className.length)
  }
  element.className = classString
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90
  } else {
    return new Date(new Date().toDateString())
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr))
}

/**
 * @returns {string}
 */
export function createUniqueString() {
  const timestamp = +new Date() + ''
  const randomNum = parseInt((1 + Math.random()) * 65536) + ''
  return (+(randomNum + timestamp)).toString(32)
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
    ele.className = ele.className.replace(reg, ' ')
  }
}

// datetime
// 格式化时间
// function parseTime(time, cFormat) {
//   if (arguments.length === 0) {
//       return null
//   }
//   const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
//   let date
//   if (typeof time === 'object') {
//       date = time
//   } else {
//       if (('' + time).length === 10) time = parseInt(time) * 1000
//       date = new Date(time)
//   }
//   const formatObj = {
//       y: date.getFullYear(),
//       m: date.getMonth() + 1,
//       d: date.getDate(),
//       h: date.getHours(),
//       i: date.getMinutes(),
//       s: date.getSeconds(),
//       a: date.getDay()
//   }
//   const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
//       let value = formatObj[key]
//       if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
//       if (result.length > 0 && value < 10) {
//           value = '0' + value
//       }
//       return value || 0
//   })
//   return time_str
// }

// 获取当天时间范围
export function getTodayDateTimeRange() {
  const dateRangeResult = []
  /* 当天0点 */
  const startTime2 = new Date(new Date(new Date().getTime()).setHours(0, 0, 0, 0))
  const endTime2 = new Date(new Date(new Date().getTime()).setHours(23, 59, 59, 999))
  dateRangeResult.push(parseTime(startTime2))
  dateRangeResult.push(parseTime(endTime2))
  return dateRangeResult
}

// 获取昨天时间范围
export function getYestodayDateTimeRange() {
  const dateRangeResult = []
  /* 当天0点 */
  const startTime2 = new Date(new Date(new Date().getTime() - 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0))
  const endTime2 = new Date(new Date(new Date().getTime() - 24 * 60 * 60 * 1000).setHours(23, 59, 59, 999))
  dateRangeResult.push(parseTime(startTime2))
  dateRangeResult.push(parseTime(endTime2))
  return dateRangeResult
}

// 本周时间
export function getCurWeekTimeRange() {
  // 起止日期数组
  const dateRangeResult = []
  // 获取当前时间
  const currentDate = new Date()
  // 返回date是一周中的某一天
  const week = currentDate.getDay()
  // 返回date是一个月中的某一天
  // const month = currentDate.getDate()

  // 一天的毫秒数
  const millisecond = 1000 * 60 * 60 * 24
  // 减去的天数
  const minusDay = week !== 0 ? week - 1 : 6
  // 本周 周一
  const monday = new Date(new Date(currentDate.getTime() - (minusDay * millisecond)).setHours(0, 0, 0, 0))
  // 本周 周日
  const sunday = new Date(new Date(monday.getTime() + (6 * millisecond)).setHours(23, 59, 59, 999))
  // 添加本周时间
  dateRangeResult.push(parseTime(monday))
  // 添加本周最后一天时间
  dateRangeResult.push(parseTime(sunday))
  return dateRangeResult
}

// 上周
export function getPreWeekTimeRange() {
// export function getPreWeekTime(vi) {
  // console.log('vi', vi)
  // 起止日期数组
  const dateRangeResult = []
  // 获取当前时间
  const currentDate = new Date()
  // 返回date是一周中的某一天
  const week = currentDate.getDay()
  // 返回date是一个月中的某一天
  // var month = currentDate.getDate()
  // 一天的毫秒数
  const millisecond = 1000 * 60 * 60 * 24
  // 减去的天数
  const minusDay = week !== 0 ? week - 1 : 6
  // 获得当前周的第一天
  const currentWeekDayOne = new Date(currentDate.getTime() - (millisecond * minusDay))
  // 上周最后一天即本周开始的前一天
  const priorWeekLastDay = new Date(new Date(currentWeekDayOne.getTime() - millisecond).setHours(23, 59, 59, 999))
  // 上周的第一天
  const priorWeekFirstDay = new Date(new Date(priorWeekLastDay.getTime() - (millisecond * 6)).setHours(0, 0, 0, 0))
  // 添加上周时间
  dateRangeResult.push(parseTime(priorWeekFirstDay))
  // 添加上周最后一天时间
  dateRangeResult.push(parseTime(priorWeekLastDay))
  return dateRangeResult
}
// 获取最近一周的起始时间
export function getLastWeekDate() {
  let end = new Date()
  let year = end.getFullYear();
  /* 0-11表示1-12月 */
  let month = (end.getMonth() + 1 + "").padStart(2, "0");
  let day = (end.getDate() + "").padStart(2, "0");
  let dateObj = {};
  let dateRangeResult = [];
  let HHMMSS = (end.getHours() + '').padStart(2, "0") + ':' + (end.getMinutes() + '').padStart(2, "0") + ':' + (end.getSeconds() + '').padStart(2, "0");
  dateObj.end = year + '-' + month + '-' + day + ' ' + HHMMSS;
  if (day - 7 <= 0) {   //如果在当月7日之前
    let startMonthDay = new Date(year, (parseInt(month) - 1), 0).getDate();    //1周前所在月的总天数
    if (month - 1 <= 0) { //如果在当年的1月份
      dateObj.start = (year - 1) + '-' + 12 + '-' + (31 - (7 - day) + "").padStart(2, "0") + ' ' + HHMMSS;
    } else {
      dateObj.start = year + '-' + (month - 1 + "").padStart(2, "0") + '-' + (startMonthDay - (7 - day) + "").padStart(2, "0") + ' ' + HHMMSS;
    }
  } else {
    dateObj.start = year + '-' + (month + "").padStart(2, "0") + '-' + (day - 7 + "").padStart(2, "0") + ' ' + HHMMSS;
  }
  dateRangeResult.push(dateObj.start);
  dateRangeResult.push(dateObj.end);
  console.log('dateObj', dateObj);
  return dateRangeResult
}
/** @description 获取昨天算起最近一周的起始时间 */
export function getBeforeLastWeekDate() {
  let startTime = new Date(new Date(new Date().getTime() - 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0))
  let endTime = new Date(new Date(new Date().getTime() - 24 * 60 * 60 * 1000).setHours(23, 59, 59, 999))
  let year = endTime.getFullYear();
  /* 0-11表示1-12月 */
  let month = (endTime.getMonth() + 1 + "").padStart(2, "0");
  let day = (endTime.getDate() + "").padStart(2, "0");
  let dateObj = {};
  let dateRangeResult = [];
  let HHMMSS = (endTime.getHours() + '').padStart(2, "0") + ':' + (endTime.getMinutes() + '').padStart(2, "0") + ':' + (endTime.getSeconds() + '').padStart(2, "0");
  let startHHMMSS = (startTime.getHours() + '').padStart(2, "0") + ':' + (startTime.getMinutes() + '').padStart(2, "0") + ':' + (startTime.getSeconds() + '').padStart(2, "0");
  dateObj.end = year + '-' + month + '-' + day + ' ' + HHMMSS;
  if (day - 7 <= 0) {   //如果在当月7日之前
    let startMonthDay = new Date(year, (parseInt(month) - 1), 0).getDate();    //1周前所在月的总天数
    if (month - 1 <= 0) { //如果在当年的1月份
      dateObj.start = (year - 1) + '-' + 12 + '-' + (31 - (7 - day) + "").padStart(2, "0") + ' ' + startHHMMSS;
    } else {
      dateObj.start = year + '-' + (month - 1 + "").padStart(2, "0") + '-' + (startMonthDay - (7 - day) + "").padStart(2, "0") + ' ' + startHHMMSS
    }
  } else {
    dateObj.start = year + '-' + (month + "").padStart(2, "0") + '-' + (day - 7 + "").padStart(2, "0") + ' ' + startHHMMSS;
  }
  dateRangeResult.push(dateObj.start)
  dateRangeResult.push(dateObj.end)
  console.log('dateObj', dateObj)
  return dateRangeResult
}

// 本月
export function getCurMonthRange() {
  // 起止日期数组
  const dateRangeResult = []
  // 获取当前时间
  const currentDate = new Date()
  // 获得当前月份0-11
  let currentMonth = currentDate.getMonth()
  // 获得当前年份4位年
  let currentYear = currentDate.getFullYear()
  // 求出本月第一天
  const firstDay = new Date(new Date(currentYear, currentMonth, 1).setHours(0, 0, 0, 0))

  /* 当为12月的时候年份需要加1 */
  /* 月份需要更新为0 也就是下一年的第一个月 */
  if (currentMonth === 11) {
    currentYear++
    currentMonth = 0
  } else {
    /* 否则只是月份增加,以便求的下一月的第一天 */
    currentMonth++
  }
  // 一天的毫秒数
  const millisecond = 1000 * 60 * 60 * 24
  // 下月的第一天
  const nextMonthDayOne = new Date(currentYear, currentMonth, 1)
  // 求出上月的最后一天
  const lastDay = new Date(new Date(nextMonthDayOne.getTime() - millisecond).setHours(23, 59, 59, 999))

  // 添加开始时间
  dateRangeResult.push(parseTime(firstDay))
  // 添加最后一天时间
  dateRangeResult.push(parseTime(lastDay))
  return dateRangeResult
}

/** @description 获取指定月的起始到结束日期范围，例如：['2024-03-01', '2024-03-31] */
export function getMonthRange(params: string) {
  // console.log('get month range params', params)
  const year = parseInt(params.substring(0, 4))
  const month = parseInt(params.substring(5, 7))
  // 起止日期数组
  const dateRangeResult = []
  // 获取当前时间
  const currentDate = new Date(year, month - 1, 1)
  // 获得当前月份0-11
  let currentMonth = currentDate.getMonth()
  // 获得当前年份4位年
  let currentYear = currentDate.getFullYear()
  // 求出本月第一天
  const firstDay = new Date(new Date(currentYear, currentMonth, 1).setHours(0, 0, 0, 0))

  /* 当为12月的时候年份需要加1 */
  /* 月份需要更新为0 也就是下一年的第一个月 */
  if (currentMonth === 11) {
    currentYear++
    currentMonth = 0
  } else {
    /* 否则只是月份增加,以便求的下一月的第一天 */
    currentMonth++
  }
  // 一天的毫秒数
  const millisecond = 1000 * 60 * 60 * 24
  // 下月的第一天
  const nextMonthDayOne = new Date(currentYear, currentMonth, 1)
  // 求出上月的最后一天
  const lastDay = new Date(new Date(nextMonthDayOne.getTime() - millisecond).setHours(23, 59, 59, 999))

  // 添加开始时间
  dateRangeResult.push(parseTime(firstDay))
  // 添加最后一天时间
  dateRangeResult.push(parseTime(lastDay))
  return dateRangeResult
}

/**
  * @description 得到本季度开始的月份
  * @param month 需要计算的月份
  ***/
function getQuarterSeasonStartMonth(month) {
  // var quarterMonthStart = 0
  // 春
  const spring = 0
  // 夏
  const summer = 3
  // 秋
  const fall = 6
  // 冬
  const winter = 9
  // 月份从0-11
  if (month < 3) {
    return spring
  }
  if (month < 6) {
    return summer
  }
  if (month < 9) {
    return fall
  }
  return winter
}

/**
* @description 获得该月的天数
* @param year年份
* @param month月份
* */
function getMonthDays(year, month) {
  // 本月第一天 1-31
  const relativeDate = new Date(year, month, 1)
  // 获得当前月份0-11
  let relativeMonth = relativeDate.getMonth()
  // 获得当前年份4位年
  let relativeYear = relativeDate.getFullYear()

  // 当为12月的时候年份需要加1
  // 月份需要更新为0 也就是下一年的第一个月
  if (relativeMonth === 11) {
    relativeYear++
    relativeMonth = 0
  } else {
    // 否则只是月份增加,以便求的下一月的第一天
    relativeMonth++
  }
  // 一天的毫秒数
  const millisecond = 1000 * 60 * 60 * 24
  // 下月的第一天
  const nextMonthDayOne = new Date(relativeYear, relativeMonth, 1)
  // 返回得到上月的最后一天,也就是本月总天数
  return new Date(nextMonthDayOne.getTime() - millisecond).getDate()
}

/**
 * 获取当前时间 格式：yyyy-MM-dd HH:MM:SS
 */
export function getCurrentTime() {
  // 当前时间
  var date = new Date()
  // 月
  var month = zeroFill(date.getMonth() + 1)
  // 日
  var day = zeroFill(date.getDate())
  // 时
  var hour = zeroFill(date.getHours())
  // 分
  var minute = zeroFill(date.getMinutes())
  // 秒
  var second = zeroFill(date.getSeconds())
  // 当前时间
  var curTime = date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second
  return curTime
}
/**
 * 获取当前时间 格式：yyyyMMddHHMMSS
 */
export function getCurrentSlimTime() {
  // 当前时间
  var date = new Date()
  // 月
  var month = zeroFill(date.getMonth() + 1)
  // 日
  var day = zeroFill(date.getDate())
  // 时
  var hour = zeroFill(date.getHours())
  // 分
  var minute = zeroFill(date.getMinutes())
  // 秒
  var second = zeroFill(date.getSeconds())
  // 当前时间
  var curTime = `${date.getFullYear()}${month}${day}${hour}${minute}${second}`
  // console.log('getCurrentSlimTime', curTime)
  return curTime
}

/**
* 补零
*/
function zeroFill(i: number) {
  // console.log('zero fill', i)
  if (i >= 0 && i <= 9) {
    return '0' + i
  } else {
    return i
  }
}

// 获得本季度的起止日期
export function getCurrentSeasonRange() {
  // 起止日期数组
  const startStop = []
  // 获取当前时间
  const currentDate = new Date()
  // 获得当前月份0-11
  const currentMonth = currentDate.getMonth()
  // 获得当前年份4位年
  const currentYear = currentDate.getFullYear()
  // 获得本季度开始月份
  const quarterSeasonStartMonth = getQuarterSeasonStartMonth(currentMonth)
  // 获得本季度结束月份
  const quarterSeasonEndMonth = quarterSeasonStartMonth + 2
  // 获得本季度开始的日期
  const quarterSeasonStartDate = new Date(new Date(currentYear, quarterSeasonStartMonth, 1).setHours(0, 0, 0, 0))
  // 获得本季度结束的日期
  const quarterSeasonEndDate = new Date(new Date(currentYear, quarterSeasonEndMonth, getMonthDays(currentYear, quarterSeasonEndMonth)).setHours(23, 59, 59, 999))
  // 加入数组返回
  startStop.push(parseTime(quarterSeasonStartDate))
  startStop.push(parseTime(quarterSeasonEndDate))
  // 返回
  return startStop
}

// 得到本年的起止日期
export function getCurrentYearRange() {
  // 起止日期数组
  const startStop = []
  // 获取当前时间
  const currentDate = new Date()
  // 获得当前年份4位年
  const currentYear = currentDate.getFullYear()

  // 本年第一天
  const currentYearFirstDate = new Date(new Date(currentYear, 0, 1).setHours(0, 0, 0, 0))
  // 本年最后一天
  const currentYearLastDate = new Date(new Date(currentYear, 11, 31).setHours(23, 59, 59, 999))
  // 添加至数组
  startStop.push(parseTime(currentYearFirstDate))
  startStop.push(parseTime(currentYearLastDate))
  // 返回
  return startStop
}

// 财年（每年4月1日~次年3月31日） cur date 20220905, '2022-04-01 00:00:00', '2023-03-31 23:59:59'
export function getFiscalYear() {
  console.log('get fiscal year')
  const startStop = []
  const keyPointTime = new Date()
  if (keyPointTime.getMonth() + 1 < 4) {
    /* 3月及之前的日期，向前推时间，直到去年3月底 */
    console.log('get fiscal year last year')
    startStop.push((keyPointTime.getFullYear() - 1) + '-04-01 00:00:00')
    startStop.push(keyPointTime.getFullYear() + '-03-31 23:59:59')
  } else {
    /* 当年4月以后，向后推算日期，到次年3月底 */
    console.log('get fiscal year next year')
    startStop.push(keyPointTime.getFullYear() + '-04-01 00:00:00')
    startStop.push((keyPointTime.getFullYear() + 1) + '-03-31 23:59:59')
  }
  return startStop
}

// 将值输出为精确到2位小数并添加千位分隔符
export function transferAnyTypeValueToFix(param, limit = 2, tho = false) {
  // console.log('transfer any type value to fix', param, limit)
  let tmpResult = ''
  /* 如果是字符串类型，需要先转换为浮点数 */
  if (isString(param)) {
    /* 去除千位分隔符 */
    if (param.indexOf(',') >= 0) {
      param = param.replace(/,/gi, '')
      // tmpResult = Math.floor(parseFloat(param)).toFixed(limit)
      if (param.indexOf('.') >= 0) {
        tmpResult = parseFloat(param).toFixed(limit)
      } else {
        tmpResult = param
      }
    } else {
      if (param.indexOf('.') >= 0) {
        tmpResult = parseFloat(param).toFixed(limit)
      } else {
        tmpResult = param
      }
    }
  } else {
    if (param.toString().indexOf('.') >= 0) {
      tmpResult = param.toFixed(limit)
    } else {
      tmpResult = param
    }
  }
  /* 添加千位分隔符 */
  if (tho) {
    if (isString(tmpResult)) {
      if (tmpResult.indexOf('.') >= 0) {
        tmpResult = parseFloat(tmpResult).toLocaleString('en-US')
      } else {
        tmpResult = Number(tmpResult).toLocaleString('en-US')
      }
    } else {
      if (tmpResult.toString().indexOf('.') >= 0) {
        tmpResult = tmpResult.toLocaleString('en-US')
      } else {
        tmpResult = tmpResult.toLocaleString('en-US')
      }
    }
  }
  /* console.log('transfer str num ', tmpResult) */
  return tmpResult
}

// 将值转化为百分比（乘100）为精确到2位小数
export function transferStringNumToPercent(param, limit = 0) {
  /* console.log('transfer any type value to percent', param, limit) */
  let tmpResult = ''
  /* 如果是字符串类型，需要先转换为浮点数 */
  if (lodash.isString(param)) {
    console.log('is string type', param)
    if (param.indexOf(',') >= 0) {
      param = param.replace(/,/gi, '')
      // tmpResult = Math.floor(parseFloat(param)).toFixed(limit)
      /* 浮点数 */
      if (param.indexOf('.') >= 0) {
        tmpResult = parseFloat(param) * 100
      } else {
        tmpResult = param * 100
      }
    } else {
      /* 浮点数 */
      if (param.indexOf('.') >= 0) {
        tmpResult = parseFloat(param) * 100
      } else {
        tmpResult = param * 100
      }
    }
  } else {
    if (param.toString().indexOf('.') >= 0) {
      tmpResult = param * 100
    } tmpResult = param
  }
  /* 精确小数点后n位（因为数据不确定，一般需要） */
  if (limit !== 0) {
    return tmpResult.toFixed(limit)
  } return tmpResult
}

// 数组类
// 降维处理（菜单专用） 20230421
export function flattenDeepTree(arr = [], childs = 'Children'): any {
  // console.log('flattenDeep arr', arr)
  return arr.reduce((flat, item) => {
    /* delete item.title */
    const tmpMeta = JSON.parse(item.meta)
    item.meta = tmpMeta
    /* console.log('flattenDeep item', item) */
    return flat.concat(
      item,
      item[childs] ? flattenDeep(item[childs], childs) : []
    )
  }, [])
}

/**
 * 从树形数据中递归筛选目标值
 * arr 数据源 
 * val 目标值
 * id 需要判断相等的字段
 * childs 子集
 */
export function valInDeep(arr = [], val, id = "Id", childs = "Children"): any {
  return arr.reduce((flat, item) => {
    return flat.concat(
      item[id] == val ? item : valInDeep(item[childs] || [], val, id, childs)
    );
  }, []);
}
/**
 * 将树形数据向下递归为一维数组
 * @param {*} arr 数据源
 * @param {*} childs  子集key
 */
export function flattenDeepOriginTree(arr = [], childs = "children"): any {
  return arr.reduce((flat, item) => {
    return flat.concat(
      item,
      item[childs] ? flattenDeepOriginTree(item[childs], childs) : []
    );
  }, []);
}

/**
 * 将数组转化成树结构 array to tree
 * @param {*} array 数据源
 * @param {*} options 字段名配置项
 */
export function arrayToTreeOrigin(
  array = [],
  options = { id: "id", pid: "pid", children: "children", rootPidVal: null },
) {
  let array_ = []; // 创建储存剔除叶子节点后的骨架节点数组
  let unique = {}; // 创建盒子辅助本轮children合并去重
  let root_pid = options.rootPidVal || [
    0,
    "0",
    undefined,
    "undefined",
    null,
    "null",
    "00000000-0000-0000-0000-000000000000",
    ""
  ]; // 可能存在的根节点pid形式
  array.forEach(item => {
    // 筛选可以插入当前节点的所有子节点
    let children_array = array.filter(
      it => it[options.pid] === item[options.id]
    );
    if (Array.isArray(item[options.children]) && item[options.children].length) {
      // 去重合并数组
      item[options.children].map(i => (unique[i[options.id]] = 1));
      item[options.children].push(
        ...children_array.filter(i => unique[i[options.id]] !== 1)
      );
    } else {
      item[options.children] = children_array;
    }
    // 当children_array有数据时插入下一轮array_，当无数据时将最后留下来的根节点树形插入数组
    let has_children = children_array.length > 0;
    if (
      has_children ||
      (!has_children && root_pid.includes(item[options.pid]))
    ) {
      array_.push(item);
    }
  });
  // 当数组内仅有根节点时退出，否组继续处理 最终递归深度次
  if (!array_.every(item => root_pid.includes(item[options.pid]))) {
    return arrayToTree(array_, options);
  } else {
    return array_;
  }
}

/**
 * @name 树结构数据转一维数组去重，去除a中b已含有的，当前应用业务：数据配置
 * @param {Object} a 当前节点
 * @param {Array} b 全部节点数组
 * @param {Object} props 属性key
 */
export function arrayDiffForTree(a: any, b: any, props: string) {
  console.log('array diff for tree', a, b, props)
  /* debugger */
  let t = cloneDeep(a)
  if (!b) {
    return a
  }
  for (let i = 0; i < b.length; i++) {
    for (let j = t.length - 1; j >= 0; j--) {
      if (t[j].hasOwnProperty('children')) {
        t[j].children.length = 0
      }
      if (t[j][props] == b[i][props] && t[j].hasOwnProperty("pid")) {
        t.splice(j, 1)
        break
      }
    }
  }
  return t
}
/**
 * @name 从一维数组中找到节点的父祖节点
 * @param {Object} item 当前节点
 * @param {Array} arr 全部节点数组
 * @param {Object} options 配置项
 */
export function findParents(item, arr, options = {
  id: 'id',
  parentId: 'parentId',
  root: 0
}) {
  let _parents: any = [];
  return function findParent(item) {
    if (item[options.parentId] === options.root) return _parents;
    const parent = arr.find(i => i[options.id] === item[options.parentId]);
    if (parent) {
      _parents.push(parent);
      return findParent(parent)
    } else {
      return _parents
    }
  }(item)
}
// 扁平化
export function convertTreeData(data: any[]) {
  for (var i = 0; i < data.length; i++) {
    if (data[i].children != undefined) {
      var temp = data[i].children;
      // 删除孩子节点
      delete data[i].children;
      // 孩子节点加入数组末尾
      for (var j = 0; j < temp.length; j++) {
        data.push(temp[j]);
      }
    }
  }
  return duplicateRemoval(data, "id");
}
//过滤 父组件 children
export function filterPid(data: any[], Pid: string | number) {
  let arr: any[] = [];
  data.forEach((item) => {
    if (item.pid === Pid && item.children.length > 0) {
      item.children = [];
    }
    arr.push(item);
  });
  return arr;
}
// 去重
function duplicateRemoval(arr: any[], key: string | any) {
  let newobj = {},
    newArr = [];
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];
    //@ts-ignore
    if (!newobj[item[key]]) {
      //@ts-ignore
      newobj[item[key]] = newArr.push(item);
    }
  }
  return newArr;
}
//克隆
export function deepCloneTreeItem(obj: any) {
  let newObj: any;
  try {
    newObj = obj.push ? [] : {};
  } catch (error) {
    newObj = {};
  }
  for (let attr in obj) {
    if (typeof obj[attr] === "object") {
      newObj[attr] = deepCloneTreeItem(obj[attr]);
    } else {
      newObj[attr] = obj[attr];
    }
  }
  return newObj;
}

// 生成树
export function spanningTree(from: any[], to: any[]) {
  // 合并数组 防止 有的地方为扁平化 再做一次
  const arr: any[] = convertTreeData([...from, ...to])
  // 手动去重 保证 id唯一
  const obj = tree(duplicateRemoval(arr, 'id'), 0)
  return obj
}
// 根据 传值 动态删除 数据
export function dynamicDeletion(data: any[], del: any[]) {
  const set = del.map((item) => {
    if (item.pid !== 0) return item.id;
  });
  console.error(set);
  data = data.filter(function (item) {
    return set.indexOf(item.id) == -1;
  });
  // 转成树
  return spanningTree(data, []);
}
// 一维数组转嵌套树结构
export function reTramsforringTree(from: any[]) {
  //合并数组 防止 有的地方为扁平化 再做一次
  // const arr: any[] = convertTreeData([...from, ...to]);
  //手动去重 保证 id唯一
  const obj = tree(from, 0)
  return obj;
}
// 树父节点
function tree(data: string | any[], attrdata: number) {
  let result = []; //存储返回结果
  if (data && data.length > 0) {
    //判断传入数组是否有值
    for (let key of data) {
      //循环该数组
      if (key.pid == attrdata) {
        //匹配一级元素
        let obj: any = {}; //存储我想要得属性
        //这里也可以增加自己想要属性
        let child = getChild(key.id, data); //匹配children 需要自身ID 数组
        if (child && child.length > 0) {
          obj.children = child;
        }
        obj = Object.assign(key, obj); //合并数据
        result.push(obj);
      }
    }
  }
  return result;
}
// getChild 方法和上述一样
function getChild(pid: string | number, data: string | any[]) {
  let result = [];
  if (data && data.length > 0) {
    for (let key of data) {
      if (key.pid == pid) {
        let obj: any = {}; //存储我想要得属性
        //这里也可以增加自己想要属性
        let child = getChild(key.id, data); //匹配children 需要自身ID 数组
        if (child && child.length > 0) {
          obj.children = child;
        }
        obj = Object.assign(key, obj); //合并数据
        result.push(obj); //添加返回chidren数组
      }
    }
  }
  return result;
}

// will be deprecated
// 单纯去除children(d 20240708)
export function removeChildren(data: any[]) {
  console.log('rc', data)
  let tmpResult: any[] = []
  data.forEach(item => {
    if (Object.prototype.hasOwnProperty.call(item, 'children')) {
      delete item.children
      tmpResult.push(item)
    } else {
      tmpResult.push(item)
    }
  })
  return tmpResult
}
