// 公共接口，基于“/admin”
import { commonApi, oldMisService } from '@/api'
// enums
import { DEFAULT_PRODUCT_CODE } from '@/enums/roleEnums'
// 加解密工具
import { aesUtil } from '@/utils/encryptn'
// 判断角色
import { getBusinessType } from '@/utils/auth'

// 商品品类 ?q=01
export function getCatGetListReq(params: {
  q: string
}) {
  return oldMisService({
    url: '/admin/cat/geList',
    method: 'get',
    params
  })
}

// 获取城市列表 ?q=01
export function getProvince(params: {
  q: string
}) {
  return commonApi({
    url: '/admin/people/arealist',
    method: 'get',
    params
  })
}

// JWT登录 20220819
export function postLoginReq(data: {
  username: string
  password: string
  captcha: string
  captchakey: string
}) {
  return commonApi.post('/admin/login', data)
}

// send apply reset post 20240411
export function postForgetPasswordReq(data: {
  username: string
  mobile: string
  email: string
}) {
  return commonApi.post('/admin/forgetPassword', data)
}

// send reset pwd post 20240411
export function postResetPasswordReq(data: {
  sign: string
  password: string
  password_confirm: string
}) {
  return commonApi.post('/admin/resetPassword', data)
}

// 图形验证码
export function getCaptchaReq() {
  return commonApi.get('/admin/captcha')
}

// 获取直营店列表
export function getStoreList() {
  return oldMisService({
    url: '/admin/members/storeList/get',
    method: 'get'
  })
}
// 获取营业员姓名
export function getStoreEmployeeList(query) {
  return oldMisService({
    url: '/admin/members/storeEmployeeList/get',
    method: 'get',
    params: query
  })
}
// 商品品类 ?q=01
export function getCatGetList(query) {
  return oldMisService({
    url: '/admin/cat/geList',
    method: 'get',
    params: query
  })
}

export function getCatList(query) {
  return commonApi({
    url: '/admin/cat/list',
    method: 'get',
    params: query
  })
}

// 平台列表
export function getCommissionPlatformListReq() {
  return commonApi({
    url: '/admin/commission/platformList',
    method: 'get'
  })
}

// admin/mvp/company/list
// 公司列表 20230131
export function getMvpPopownerCompanyListReq(data) {
  return commonApi({
    url: (aesUtil.deCryptPlainText(getBusinessType()) === DEFAULT_PRODUCT_CODE ? '/admin' : '/business') + '/mvp/popowner/company/list',
    method: 'get',
    params: data
  })
}

// 添加openkey api_key (https://docs.lecoosys.com/web/#/88/814)
export function postChatAddReq(data) {
  return commonApi({
    url: '/admin/chat/add',
    method: 'post',
    data
  })
}
// chatGPT查询
export function postChatQueryReq(data) {
  return commonApi({
    url: '/admin/chat/query',
    method: 'post',
    data
  })
}

// export function logout() {
//   return request({
//     url: '/vue-element-admin/user/logout',
//     method: 'post'
//   })
// }

// 集团列表
export function getGroupListReq() {
  return commonApi({
    url: '/admin/group/selectListSearch',
    method: 'get'
  })
}

// 公司列表
export function getCompanyListReq() {
  return commonApi({
    url: '/admin/company/selectListSearch',
    method: 'get'
  })
}

// 获取公司列表
export function getCompanyListSearchReq(data) {
  return commonApi({
    url: (aesUtil.deCryptPlainText(getBusinessType()) === DEFAULT_PRODUCT_CODE ? '/admin' : '/business') + '/company/selectListSearch',
    method: 'get',
    params: data
  })
}

// 获取审批人列表
export function getApproveListSearchReq(data) {
  return commonApi({
    url: (aesUtil.deCryptPlainText(getBusinessType()) === DEFAULT_PRODUCT_CODE ? '/admin' : '/business') + '/group/usersSelectListSearch',
    method: 'get',
    params: data
  })
}
