import { cloneDeep, isString } from 'lodash-es'
import type { RouteMeta, RouteRecordRaw } from 'vue-router'
import useSettingsStore from './settings'
import useUserStore from './user'
import { resolveRoutePath } from '@/utils'
import { systemRoutes, constantRoutes } from '@/router/routes'
import apiApp from '@/api/modules/app'
import { getMenuList } from '@/api/modules/user'
import type { Route } from '@/types/global'
// componentsMap 前端组件Map数据，遍历后端返回路由数据component的字符串key转换为function
import { componentsMap } from '@/vendor/componentsMap'

const useRouteStore = defineStore(
  // 唯一ID
  'route',
  () => {
    const settingsStore = useSettingsStore()
    const userStore = useUserStore()
    // 已生成导航
    const isGenerate = ref(false)
    const routesRaw = ref<Route.recordMainRaw[]>([])
    const filesystemRoutesRaw = ref<RouteRecordRaw[]>([])
    const currentRemoveRoutes = ref<(() => void)[]>([])

    // 将多层嵌套路由处理成两层，保留顶层和最子层路由，中间层级将被拍平
    function flatAsyncRoutes<T extends RouteRecordRaw>(route: T): T {
      if (route.children) {
        route.children = flatAsyncRoutesRecursive(route.children, [{
          path: route.path,
          title: route.meta?.title,
          hide: !route.meta?.breadcrumb && route.meta?.breadcrumb === false,
        }], route.path, route.meta?.auth)
      }
      return route
    }
    function flatAsyncRoutesRecursive(routes: RouteRecordRaw[], breadcrumb: Route.breadcrumb[] = [], baseUrl = '', baseAuth: RouteMeta['auth']): RouteRecordRaw[] {
      const res: RouteRecordRaw[] = []
      routes.forEach((route) => {
        if (route.children && route.children.length) {
          const childrenBaseUrl = resolveRoutePath(baseUrl, route.path)
          const childrenBaseAuth = baseAuth ?? route.meta?.auth
          const tmpBreadcrumb = cloneDeep(breadcrumb)
          tmpBreadcrumb.push({
            path: childrenBaseUrl,
            title: route.meta?.title,
            hide: !route.meta?.breadcrumb && route.meta?.breadcrumb === false,
          })
          const tmpRoute = cloneDeep(route)
          tmpRoute.path = childrenBaseUrl
          if (!tmpRoute.meta) {
            tmpRoute.meta = {}
          }
          tmpRoute.meta.auth = childrenBaseAuth
          tmpRoute.meta.breadcrumbNeste = tmpBreadcrumb
          delete tmpRoute.children
          res.push(tmpRoute)
          const childrenRoutes = flatAsyncRoutesRecursive(route.children, tmpBreadcrumb, childrenBaseUrl, childrenBaseAuth)
          childrenRoutes.forEach((item) => {
            // 如果 path 一样则覆盖，因为子路由的 path 可能设置为空，导致和父路由一样，直接注册会提示路由重复
            if (res.some(v => v.path === item.path)) {
              res.forEach((v, i) => {
                if (v.path === item.path) {
                  res[i] = item
                }
              })
            }
            else {
              res.push(item)
            }
          })
        } else {
          let tmpRoute = cloneDeep(route)
          tmpRoute.path = resolveRoutePath(baseUrl, tmpRoute.path)
          // 处理面包屑导航
          const tmpBreadcrumb = cloneDeep(breadcrumb)
          tmpBreadcrumb.push({
            path: tmpRoute.path,
            title: tmpRoute.meta?.title,
            hide: !tmpRoute.meta?.breadcrumb && tmpRoute.meta?.breadcrumb === false,
          })
          if (!tmpRoute.meta) {
            tmpRoute.meta = {}
          }
          // 解决部分情况meta数据是stringify的情况
          if (tmpRoute.meta.length !== 0 && typeof tmpRoute.meta === 'string') {
            tmpRoute.meta = JSON.parse(tmpRoute.meta)
          }
          tmpRoute.meta.auth = baseAuth ?? tmpRoute.meta?.auth
          tmpRoute.meta.breadcrumbNeste = tmpBreadcrumb
          res.push(tmpRoute)
        }
      })
      return res
    }
    // 扁平化路由(由原“flatRoutes”修改而来)（将三级及以上路由数据拍平成二级）
    const flatRoutes = computed(() => {
      const settingsStore = useSettingsStore()
      const returnRoutes: RouteRecordRaw[] = []
      /* 平台用户的返回老MIS路由对象 */
      /* const returnURLObj = {
        id: 998,
        parentId: 0,
        enabled: 1,
        menu_type: 0,
        alwaysShow: true,
        hidden: false,
        name: "",
        orders: 0,
        component: 'layout',
        path: "",
        meta: { title: '其他', icon: 'icon-link', roles: ['admin'] },
        redirect: '',
        children: [
          {
            id: 999,
            parentId: 998,
            enabled: 1,
            menu_type: 1,
            alwaysShow: true,
            hidden: false,
            name: "",
            orders: 0,
            component: 'layout',
            path: `//${window.location.hostname === 'localhost' ? 'mis.test.lecoosys.com' : window.location.hostname}/admin/`,
            meta: { title: '返回原MIS', icon: 'icon-link', roles: ['admin'] },
            redirect: '',
          }
        ]
      } */
      // console.log('store routes routesRaw', routesRaw.value)
      if (routesRaw.value) {
        if (settingsStore.settings.app.routeBaseOn !== 'filesystem') {
          routesRaw.value.forEach((item) => {
            /* console.log('routesRaw item', item) */
            if (Object.prototype.hasOwnProperty.call(item, 'children') && Object.keys(item.children).length) {
              const tmpRoutes = cloneDeep(item.children) as RouteRecordRaw[]
              tmpRoutes.map((v) => {
                v.meta = v.meta
                if (!v.meta) {
                  v.meta = {}
                }
                v.meta.auth = item.meta?.auth ?? v.meta?.auth
                return v
              })
              returnRoutes.push(...tmpRoutes)
            }
          })
          returnRoutes.forEach(item => {
            flatAsyncRoutes(item)
          })
          // console.log('return routes', returnRoutes)
          // returnRoutes.push(returnURLObj)
        } else {
          returnRoutes.push(...cloneDeep(filesystemRoutesRaw.value))
        }
      }
      // console.log('flatRoutes result', returnRoutes)
      return returnRoutes
    })
    const flatSystemRoutes = computed(() => {
      const routes = [...systemRoutes]
      routes.forEach(item => flatAsyncRoutes(item))
      return routes
    })

    // 判断是否有权限
    function hasPermission(permissions: string[], route: Route.recordMainRaw | RouteRecordRaw) {
      let isAuth = false
      if (route.meta?.auth) {
        isAuth = permissions.some((auth) => {
          if (typeof route.meta?.auth === 'string') {
            return route.meta.auth !== '' ? route.meta.auth === auth : true
          }
          else if (typeof route.meta?.auth === 'object') {
            return route.meta.auth.length > 0 ? route.meta.auth.includes(auth) : true
          }
          else {
            return false
          }
        })
      }
      else {
        isAuth = true
      }
      return isAuth
    }
    // 根据权限过滤路由
    function filterAsyncRoutes<T extends Route.recordMainRaw[] | RouteRecordRaw[]>(routes: T, permissions: string[]): T {
      const res: any = []
      routes.forEach((route) => {
        if (hasPermission(permissions, route)) {
          const tmpRoute = cloneDeep(route)
          if (tmpRoute.children) {
            tmpRoute.children = filterAsyncRoutes(tmpRoute.children, permissions)
            tmpRoute.children.length && res.push(tmpRoute)
          }
          else {
            res.push(tmpRoute)
          }
        }
      })
      return res
    }
    const routes = computed(() => {
      // console.log('routes computed.')
      let returnRoutes: Route.recordMainRaw[]
      // 如果权限功能开启，则需要对路由数据进行筛选过滤
      if (settingsStore.settings.app.enablePermission) {
        returnRoutes = filterAsyncRoutes(routesRaw.value as any, userStore.permissions)
      }
      else {
        returnRoutes = cloneDeep(routesRaw.value) as any
      }
      return returnRoutes
    })

    // 根据权限动态生成路由（前端生成）
    async function generateRoutesAtFront(asyncRoutes: Route.recordMainRaw[]) {
      console.log('generate routes at front > ', asyncRoutes)
      // 设置 routes 数据
      routesRaw.value = cloneDeep(asyncRoutes) as any
      /* 是settings.app的enablePermission */
      if (settingsStore.settings.app.enablePermission) {
        console.log('generate routes at front enable permission >')
        await userStore.getPermissions()
      }
      isGenerate.value = true
    }
    // 格式化后端路由数据
    function formatBackRoutes(routes: any, views = import.meta.glob('../../views/**/*.vue')): Route.recordMainRaw[] {
      return routes.map((route: any) => {
        // console.log('route store: format back routes', route)
        switch (route.component) {
          case 'Layout':
            route.component = () => import('@/layouts/index.vue')
            break
          default:
            if (route.component) {
              route.component = views[`../../views/${route.component}`]
            } else {
              delete route.component
            }
        }
        if (route.children) {
          route.children = formatBackRoutes(route.children, views)
        }
        return route
      })
    }
    // 递归处理菜单数据 update by zzl81cn at 20240401
    function formatNestedRoutes(routes: any): Route.recordMainRaw[] {
      return routes.map((route: any) => {
        // console.log('route store: format back routes', route)
        switch (route.component) {
          case 'layout':
            // console.log('route store with layout component', route.component)
            route.component = () => import('@/layouts/index.vue')
            route.path = route.path
            route.redirect = Object.prototype.hasOwnProperty.call(route, 'children') && route.children.length === 0 ? '' : route.redirect ? route.redirect : ''
            route.name = route.name
            route.menu_type = route.menu_type
            route.meta = isString(route.meta) ? JSON.parse(route.meta) : route.meta
            route.meta.cache = false
            route.meta.hidden = route.hidden ? route.hidden : false
            route.alwaysShow = route.alwaysShow ? route.alwaysShow : false
            if (route.menu_type === 1) {
              route.path = route.path.indexOf('https') >= 0 ? route.path: 'https:' + route.path
              route.meta.link = route.path.indexOf('https') >= 0 ? route.path: 'https:' + route.path
            }
            break
          default:
            if (route.component) {
              // console.log('route store unlayout component', route.component)
              route.component = componentsMap[route.component]
              /* 处理menu-item的细节移出到if-else后面，此处只处理components */
            } else {
              delete route.component
            }
            route.path = route.path
            route.redirect = Object.prototype.hasOwnProperty.call(route, 'children') && route.children.length === 0 ? '' : route.redirect ? route.redirect : ''
            route.name = route.name
            route.menu_type = route.menu_type
            route.meta = isString(route.meta) ? JSON.parse(route.meta) : route.meta
            route.meta.cache = false
            route.meta.hidden = route.hidden ? route.hidden : false
            route.alwaysShow = route.alwaysShow ? route.alwaysShow : false
            if (route.menu_type === 1) {
              route.path = route.path.indexOf('https') >= 0 ? route.path: 'https:' + route.path
              route.meta.link = route.path.indexOf('https') >= 0 ? route.path: 'https:' + route.path
            }
        }
        if (route.children.length > 0) {
          route.children = formatNestedRoutes(route.children)
        }
        return route
      })
    }

    // 设置 routes 数据
    /* const returnURLObj = {
      id: 998,
      parentId: 0,
      enabled: 1,
      menu_type: 0,
      alwaysShow: true,
      hidden: false,
      name: "",
      orders: 0,
      component: 'layout',
      path: "",
      meta: { title: '其他', icon: 'icon-link', roles: ['admin'] },
      redirect: '',
      children: [
        {
          id: 999,
          parentId: 998,
          enabled: 1,
          menu_type: 1,
          alwaysShow: true,
          hidden: false,
          name: "",
          orders: 0,
          component: 'layout',
          path: `https://${window.location.hostname === 'localhost' ? 'mis.test.lecoosys.com' : window.location.hostname}/admin/`,
          meta: { title: '返回原MIS', icon: 'icon-link', roles: ['admin'] },
          redirect: '',
        }
      ]
    } */
    // 根据权限动态生成路由（后端获取）
    async function generateRoutesAtBack() {
      console.log('generator menu at back')
      const TMP_ROUTES = [{
        meta: {
          "title": "MIS",
          "icon": "uim:box"
        },
        children: []
      }]
      await getMenuList().then((res) => {
        // console.log('generate routes at back', res)
        const tmpMenus = cloneDeep(res.data.menus)
        // tmpMenus.push(returnURLObj)
        // routesRaw.value = formatBackRoutes(res.data)
        // TMP_ROUTES[0].children = TMP_ROUTES[0].children.concat(formatNestedRoutes(res.data.menus))
        TMP_ROUTES[0].children = TMP_ROUTES[0].children.concat(formatNestedRoutes(tmpMenus))
        constantRoutes.forEach(item => {
          TMP_ROUTES[0].children.unshift(item)
        })
        // console.log('generate routes at back', TMP_ROUTES)
        // TMP_ROUTES[0].children.push(returnURLObj)
        routesRaw.value = TMP_ROUTES
        if (settingsStore.settings.app.enablePermission) {
          userStore.getPermissions()
        }
        isGenerate.value = true
      }).catch(() => {})
    }
    // 根据权限动态生成路由（文件系统生成）
    async function generateRoutesAtFilesystem(asyncRoutes: RouteRecordRaw[]) {
      // 设置 routes 数据
      filesystemRoutesRaw.value = cloneDeep(asyncRoutes) as any
      // 如果权限功能开启，则需要对路由数据进行筛选过滤
      if (settingsStore.settings.app.enablePermission) {
        await userStore.getPermissions()
      }
      isGenerate.value = true
    }
    // 记录 accessRoutes 路由，用于登出时删除路由
    function setCurrentRemoveRoutes(routes: (() => void)[]) {
      currentRemoveRoutes.value = routes
    }
    // 清空动态路由
    function removeRoutes() {
      isGenerate.value = false
      // routesRaw.value = []
      // filesystemRoutesRaw.value = []
      // currentRemoveRoutes.value.forEach((removeRoute) => {
      //   removeRoute()
      // })
      // currentRemoveRoutes.value = []
    }
    // 扁平化路由（将三级及以上路由数据拍平成二级）
    /* const flatRoutes = computed(() => {
      const settingsStore = useSettingsStore()
      const returnRoutes: RouteRecordRaw[] = []
      console.log('store routes routesRaw', routesRaw.value)
      if (routesRaw.value) {
        if (settingsStore.settings.app.routeBaseOn !== 'filesystem') {
          routesRaw.value.forEach((item) => {
            const tmpRoutes = cloneDeep(item.children) as RouteRecordRaw[]
            tmpRoutes.map((v) => {
              if (!v.meta) {
                v.meta = {}
              }
              v.meta.auth = item.meta?.auth ?? v.meta?.auth
              return v
            })
            returnRoutes.push(...tmpRoutes)
          })
          returnRoutes.forEach(item => flatAsyncRoutes(item))
        } else {
          returnRoutes.push(...cloneDeep(filesystemRoutesRaw.value))
        }
      }
      console.log('flatRoutes result', returnRoutes)
      return returnRoutes
    }) */
    /**
     * @description 将路由数据项的component利用componentsMap转为function
     * @param item
     * @param isParent
     */
    /* function generateRouter(route: Route.recordMainRaw[]) {
      console.log('generator router item ', JSON.stringify(item))
      const ROUTER = {
        if (route.component) {
          route.component = componentsMap[route.component]
        } else {
          delete route.component
        }
        route.path = route.menu_type === 1 ? 'https:' + route.path : route.path
        route.redirect = Object.prototype.hasOwnProperty.call(route, 'children') && route.children.length === 0 ? '' : route.redirect ? route.redirect : ''
        route.name = route.name
        route.menu_type = route.menu_type
        route.meta = isString(route.meta) ? JSON.parse(route.meta) : route.meta
        route.hidden = route.hidden ? route.hidden : false
        route.alwaysShow = route.alwaysShow ? route.alwaysShow : false
      }
      return ROUTER
    } */


    return {
      isGenerate,
      routes,
      currentRemoveRoutes,
      flatRoutes,
      flatSystemRoutes,
      generateRoutesAtFront,
      generateRoutesAtBack,
      generateRoutesAtFilesystem,
      setCurrentRemoveRoutes,
      removeRoutes,
    }
  },
)

export default useRouteStore
