//
// 全局filter过滤器
//

// import parseTime, formatTime and set to filter
export { parseTime, formatTime } from '@/utils'

/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
  if (time === 1) {
    return time + label
  }
  return time + label + 's'
}

/**
 * @param {number} time
 */
export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time)
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute')
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour')
  } else {
    return pluralize(~~(between / 86400), ' day')
  }
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
  const si = [
    { value: 1E18, symbol: 'E' },
    { value: 1E15, symbol: 'P' },
    { value: 1E12, symbol: 'T' },
    { value: 1E9, symbol: 'G' },
    { value: 1E6, symbol: 'M' },
    { value: 1E3, symbol: 'k' }
  ]
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
    }
  }
  return num.toString()
}

/* 两位小数 */
export function toUpFloor(num) {
  console.log('to up floor', num)
  if (num) {
    const tmpVal = parseFloat(num)
    return tmpVal.toFixed(2)
  } return ''
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

// 人群包
// 数值类的空值转中横线
export function transEmptyStringToMiddleLine(param) {
  return param !== '' ? param : '--'
}

// 计算方式
export function transCalcTypes(param) {
  // let tmpParam = ''
  // if (param !== '') {
  //   tmpParam = param.toString()
  // }
  switch (param) {
    case '1':
      return '动态'
    case '2':
      return '非动态'
    default:
      return ''
  }
}

// 是、否
export function transYesOrNo(param) {
  switch (param) {
    case '1':
      return '是'
    case '2':
      return '否'
    default:
      return ''
  }
}

// 关联关系
export function transRelation(param) {
  switch (param) {
    case 'and':
      return '且'
    case 'or':
      return '或'
    default:
      return ''
  }
}
// 中文一、二、三转换 20220829 add, 20220829 update.
/* export function trannsNoAtChs(param) {
  const tmpStr = param.toString()
  switch (tmpStr) {
    case '1':
      return '一'
    case '2':
      return '二'
    case '3':
      return '三'
    default:
      return ''
  }
} */
